import { useSelector } from 'react-redux';
import Button from "../../atom/button.jsx";
import { useEffect, useRef, useState } from "react";
import TextArea from "../../atom/textarea.jsx";
import DropDown from "../../atom/dropDown.jsx";
import useValidation from "../../../utils/validation.js";
import CustomInput from "../../molecules/customInput.jsx";
import RadioButton from "../../molecules/radio/radio.jsx";
import CheckBox from "../../molecules/checkBox/checkBox.jsx";
import moment from "moment";
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import Autocomplete from "react-google-autocomplete";
import * as env from '../../../config/env.js';
import useCustomComposable from '../../../utils/composables/index.js';
import { toast } from 'react-toastify';
const EditMyProfile = (props) => {
    const timePickerRef3 = useRef(null);
    const { focusOnDropdown,focusNextInput } = useCustomComposable();
    const userDetails = useSelector(state => state?.user?.user);
    const dropDown = useSelector(state => state?.getDropDown?.getDropDown);
    const [totalLength,setTotalLength] = useState(0); 
    const [myProfileObject,setMyProfileObject] = useState({
        basicProfileCreatedFor: {value: userDetails?.basicProfileCreatedFor ? userDetails?.basicProfileCreatedFor :'' ,rules:"required",name: "profile for",error: ""},
        firstName: {value: userDetails?.firstName ? userDetails?.firstName :'' ,rules:"required|max:50",name: "first name",error: ""},
        lastName: {value: userDetails?.lastName ? userDetails?.lastName :'' ,rules:"required|max:50",name: "last name",error: ""},
        basicAge: {value: userDetails?.basicAge ? userDetails?.basicAge :'-' ,rules:"required",name: "basic age",error: ""},
        basicHeight:{value: userDetails?.basicHeight ? userDetails?.basicHeight : '',rules:"required",name: "height",error: ""},
        basicGor: {value: userDetails?.basicGor ? userDetails?.basicGor : '',rules:"required|min:3|max:100",name: "gor",error: ""},
        basicWeight:{value: userDetails?.basicWeight ? userDetails?.basicWeight : '',rules:"",name: "weight",error: ""},
        basicBodyType:{value: userDetails?.basicBodyType ? userDetails?.basicBodyType : '',rules:"",name: "body type",error: ""},
        basicMaritalStatus:{value: userDetails?.basicMaritalStatus ? userDetails?.basicMaritalStatus : '',rules:"required",name: "marital status",error: ""},
        basicHaveAChildren:{value: userDetails?.basicHaveAChildren ? userDetails?.basicHaveAChildren : null,rules:"",name: "have a children",error: ""},
        basicNumberOfChildrens:{value: userDetails?.basicNumberOfChildrens ? userDetails?.basicNumberOfChildrens : 0,rules:"regex:^[0-9]+$",name: "number of children",error: ""},
        basicAwaitingDivorcedRemark:{value: userDetails?.basicAwaitingDivorcedRemark ? userDetails?.basicAwaitingDivorcedRemark : '',rules:"",name: "awaiting divorce remark",error: ""},
        basicDivorcedRemark:{value: userDetails?.basicDivorcedRemark ? userDetails?.basicDivorcedRemark : '',rules:"",name: "divorce remark",error: ""},
        basicWidowedRemark:{value: userDetails?.basicWidowedRemark ? userDetails?.basicWidowedRemark : '',rules:"",name: "widowed remark",error: ""},
        basicReligion: {value: userDetails?.basicReligion ? userDetails?.basicReligion : '',rules:"required",name: "religion",error: ""},
        basicCaste: {value: userDetails?.basicCaste ? userDetails?.basicCaste : '',rules:"required",name: "caste",error: ""},
        basicMotherTongue: {value: userDetails?.basicMotherTongue ? userDetails?.basicMotherTongue :'' ,rules:"required",name: "mother tongue",error: ""},
        basicDisability:{value: userDetails?.basicDisability ? userDetails?.basicDisability : '',rules:"required",name: "disability",error: ""},
        basicDisabilityDetail:{value: userDetails?.basicDisabilityDetail ? userDetails?.basicDisabilityDetail : '',rules:"",name: "basicDisabilityDetail",error: ""},
        basicWillingToMarry:{value: userDetails?.basicWillingToMarry || false,rules:"",name: "marry",error: ""},
        aboutMe:{value: userDetails?.aboutMe ? userDetails?.aboutMe : '',rules:"required|min:3|max:500",name: "about me",error: ""},
        locationCountry:{value: userDetails?.locationCountry ? userDetails?.locationCountry : '',rules:"required",name: "country",error: ""},
        locationCity:{value: userDetails?.locationCity ? userDetails?.locationCity : '',rules:"required",name: "city",error: ""},
        locationState:{value: userDetails?.locationState ? userDetails?.locationState : '',rules:"required",name: "state",error: ""},
        locationResident:{value: userDetails?.locationResident ? userDetails?.locationResident : '',rules:"",name: "resident",error: ""},
        horoscopeStar:{value: userDetails?.horoscopeStar ? userDetails?.horoscopeStar : '',rules:"",name: "star",error: ""},
        horoscopeDateOfBirth:{value: userDetails?.horoscopeDateOfBirth ? userDetails?.horoscopeDateOfBirth : '',rules:"required",name: "date of birth",error: ""},
        horoscopeTimeOfBirth:{value: userDetails?.horoscopeTimeOfBirth ? userDetails?.horoscopeTimeOfBirth : '',rules:"",name: "time of birth",error: ""},
        horoscopeStateOfBirth:{value: userDetails?.horoscopeStateOfBirth ? userDetails?.horoscopeStateOfBirth : '',rules:"",name: "state of birth",error: ""},
        horoscopeCityOfBirth:{value: userDetails?.horoscopeCityOfBirth ? userDetails?.horoscopeCityOfBirth : '',rules:"",name: "city of birth",error: ""},
        horoscopeManglik:{value: userDetails?.horoscopeManglik ? userDetails?.horoscopeManglik : '',rules:"required",name: "manglik",error: ""},
        horoscopeBelieveInHoroscope:{value: userDetails?.horoscopeBelieveInHoroscope ? userDetails?.horoscopeBelieveInHoroscope : 1,rules:"",name: "believe",error: ""},
        horoscopeMatch:{value: userDetails?.horoscopeMatch ? userDetails?.horoscopeMatch : 1,rules:"",name: "match",error: ""},
        educationHighestQualification:{value: userDetails?.educationHighestQualification ? userDetails?.educationHighestQualification : '',rules:"required",name: "highest qualification",error: ""},
        educationAdditionalDegree:{value: userDetails?.educationAdditionalDegree ? userDetails?.educationAdditionalDegree : '',rules:"",name: "additional degree",error: ""},
        educationSubject:{value: userDetails?.educationSubject ? userDetails?.educationSubject : '',rules:"",name: "education subject",error: ""},
        educationInDetail:{value: userDetails?.educationInDetail ? userDetails?.educationInDetail : '',rules:"",name: "additional education detail",error: ""},
        educationCollege:{value: userDetails?.educationCollege ? userDetails?.educationCollege : '',rules:"",name: "education college",error: ""},
        educationEmployeedIn:{value: userDetails?.educationEmployeedIn ? userDetails?.educationEmployeedIn : '',rules:"",name: "employed in",error: ""},
        educationOccupation:{value: userDetails?.educationOccupation ? userDetails?.educationOccupation : '',rules:"required",name: "education occupation",error: ""},
        educationOccupationDetail:{value: userDetails?.educationOccupationDetail ? userDetails?.educationOccupationDetail : '',rules:"",name: "education occupation detail",error: ""},
        educationOrganization:{value: userDetails?.educationOrganization ? userDetails?.educationOrganization : '',rules:"",name: "organization",error: ""},
        educationAnnualIncome:{value: userDetails?.educationAnnualIncome ? userDetails?.educationAnnualIncome : '',rules:"",name: "annual income",error: ""},
        familyFatherOccupation:{value: userDetails?.familyFatherOccupation ? userDetails?.familyFatherOccupation : '',rules:"",name: "father occupation",error: ""},
        familyMotherOccupation:{value: userDetails?.familyMotherOccupation ? userDetails?.familyMotherOccupation : '',rules:"",name: "mother occupation",error: ""},
        familySister:{value: userDetails?.familySister ? userDetails?.familySister : null,rules:"",name: "family sister",error: ""},
        familyMarriedSister:{value: userDetails?.familyMarriedSister ? userDetails?.familyMarriedSister : null,rules:"",name: "family married sister",error: ""},
        familyBrother:{value: userDetails?.familyBrother ? userDetails?.familyBrother : null,rules:"",name: "family brother",error: ""},
        familyMarriedBrother:{value: userDetails?.familyMarriedBrother ? userDetails?.familyMarriedBrother : null,rules:"",name: "family married brother",error: ""},
        familyStatus:{value: userDetails?.familyStatus ? userDetails?.familyStatus : '',rules:"required",name: "family status",error: ""},
        familyType:{value: userDetails?.familyType ? userDetails?.familyType : '',rules:"",name: "family type",error: ""},
        familyValues:{value: userDetails?.familyValues ? userDetails?.familyValues : '',rules:"required",name: "family values",error: ""},
        familyIncome:{value: userDetails?.familyIncome ? userDetails?.familyIncome : '',rules:"",name: "family Income",error: ""},
        familyLivingState:{value: userDetails?.familyLivingState ? userDetails?.familyLivingState : '',rules:"",name: "family state",error: ""},
        familyLivingCountry:{value: userDetails?.familyLivingCountry ? userDetails?.familyLivingCountry : '',rules:"",name: "family country",error: ""},
        familyLivingIn:{value: userDetails?.familyLivingIn ? userDetails?.familyLivingIn : '',rules:"",name: "family livingIn",error: ""},
        familyLiveWithFamily:{value: userDetails?.familyLiveWithFamily ? userDetails?.familyLiveWithFamily : 1,rules:"",name: "live with family",error: ""},
        lifeStyleEatingHabits:{value: userDetails?.lifeStyleEatingHabits ? userDetails?.lifeStyleEatingHabits : '',rules:"",name: "eating habits",error: ""},
        lifeStyleSmokingHabits:{value: userDetails?.lifeStyleSmokingHabits ? userDetails?.lifeStyleSmokingHabits : '',rules:"",name: "smoking habits",error: ""},
        lifeStyleDrinkingHabits:{value: userDetails?.lifeStyleDrinkingHabits ? userDetails?.lifeStyleDrinkingHabits : '',rules:"",name: "drinking habits",error: ""},
        lifeStyleOwnsHouse:{value: userDetails?.lifeStyleOwnsHouse ? userDetails?.lifeStyleOwnsHouse : 2,rules:"",name: "owns house",error: ""},
        lifeStyleOwnsCar:{value: userDetails?.lifeStyleOwnsCar ? userDetails?.lifeStyleOwnsCar : 2,rules:"",name: "owns car",error: ""},
        lifeStyleLanguages:{value: userDetails?.lifeStyleLanguages ? userDetails?.lifeStyleLanguages : '',rules:"",name: "life style languages",error: ""},
        interestFavouriteVacationDestination:{value: userDetails?.interestFavouriteVacationDestination && userDetails?.interestFavouriteVacationDestination?.length ? userDetails?.interestFavouriteVacationDestination : [],rules:"",name: "favourite vacation destination",error: ""},
        interestFavouriteCusine:{value: userDetails?.interestFavouriteCusine && userDetails?.interestFavouriteCusine?.length ? userDetails?.interestFavouriteCusine : [],rules:"",name: "favourite cuisine",error: ""},
        interestSportsFitness:{value: userDetails?.interestSportsFitness && userDetails?.interestSportsFitness?.length ? userDetails?.interestSportsFitness : [],rules:"",name: "sports fitness",error: ""},
        interestPreferredMovies:{value: userDetails?.interestPreferredMovies && userDetails?.interestPreferredMovies?.length ? userDetails?.interestPreferredMovies : [],rules:"",name: "preferred movies",error: ""},
        interestFavouriteTVShows:{value: userDetails?.interestFavouriteTVShows && userDetails?.interestFavouriteTVShows?.length ? userDetails?.interestFavouriteTVShows : [],rules:"",name: "favourite tv shows",error: ""},
        interestDressStyle:{value: userDetails?.interestDressStyle && userDetails?.interestDressStyle?.length ? userDetails?.interestDressStyle : [],rules:"",name: "dress style",error: ""},
        interestFavouriteRead:{value: userDetails?.interestFavouriteRead && userDetails?.interestFavouriteRead?.length ? userDetails?.interestFavouriteRead : [],rules:"",name: "favourite read",error: ""},
        interestMusic:{value: userDetails?.interestMusic && userDetails?.interestMusic?.length ? userDetails?.interestMusic : [],rules:"",name: "music",error: ""},
        interest:{value: userDetails?.interest && userDetails?.interest?.length ? userDetails?.interest : [],rules:"",name: "interest",error: ""},
        interestHobbies:{value: userDetails?.interestHobbies && userDetails?.interestHobbies?.length ? userDetails?.interestHobbies : [],rules:"",name: "hobbies",error: ""}
    });
    const [changesInForm,setChangesInForm] = useState({});
    const [age, setAge] = useState(null);
    useEffect(() => {
        const calculateAge = (dateOfBirth) => {
            const dob = moment(dateOfBirth);
            const now = moment();
            return now.diff(dob, 'years');
        };
    
        const dob = userDetails?.horoscopeDateOfBirth || new Date();
        const age = calculateAge(dob)
        setAge(age);
    }, []);

    useEffect(() => {
        if(props?.handleSaveEditProfile === true){
            handleSubmit();
        }
    },[props.handleSaveEditProfile])
    
    const  { checkErrors,checkAllFields } = useValidation();

    const getInitialTimes = (timeString) => {
        return timeString ? moment(timeString, 'HH:mm') : null;
    };
    const handleChange = (value) => {
        if(!(value.target.value)){
            checkErrors({
                'field': myProfileObject.locationState,
                'name': 'state',
                'validations': myProfileObject.locationState.rules,                
                'value': ''
            });
            checkErrors({
                'field': myProfileObject.locationCity,
                'name': 'city',
                'validations': myProfileObject.locationCity.rules,                
                'value': ''
            });
            checkErrors({
                'field': myProfileObject.locationCountry,
                'name': 'country',
                'validations': myProfileObject.locationCountry.rules,                
                'value': ''
            });
            setChangesInForm(prevFormData => ({
                ...prevFormData,
                locationState: { ...myProfileObject.locationState, value: '' },
                locationCity: { ...myProfileObject.locationCity, value: '' },
                locationCountry: { ...myProfileObject.locationCountry, value: '' }
            }));
            setMyProfileObject(prevFormData => ({
                ...prevFormData,
                locationState: { ...myProfileObject.locationState, value: '' },
                locationCity: { ...myProfileObject.locationCity, value: '' },
                locationCountry: { ...myProfileObject.locationCountry, value: '' }
            }));
        }
    }
    const handleChangeLivingWith = (value) => {
        if(!(value.target.value)){
            checkErrors({
                'field': myProfileObject.familyLivingCountry,
                'name': 'family country',
                'validations': myProfileObject.familyLivingCountry.rules,
                'value': ''
            });
            checkErrors({
                'field': myProfileObject.familyLivingState,
                'name': 'family state',
                'validations': myProfileObject.familyLivingState.rules,
                'value': ''
            });
            checkErrors({
                'field': myProfileObject.familyLivingIn,
                'name': 'family livingIn',
                'validations': myProfileObject.familyLivingIn.rules,
                'value': ''
            });
            setChangesInForm({ ...changesInForm, familyLivingIn: {...changesInForm.familyLivingIn,value:''},familyLivingCountry: {...changesInForm.familyLivingCountry,value:''},familyLivingState: {...changesInForm.familyLivingState,value:''}});
            setMyProfileObject(prevFormData => ({
                ...prevFormData,
                familyLivingState: { ...myProfileObject.familyLivingState, value: '' },
                familyLivingIn: { ...myProfileObject.familyLivingIn, value: '' },
                familyLivingCountry: { ...myProfileObject.familyLivingCountry, value: '' }
            }));
        }
    }
    const handleSelect = (e,id) => {
        if(id === 'profileCreatorFor'){
            checkErrors({
                'field': myProfileObject.basicProfileCreatedFor,
                'name': 'profile for',
                'validations': myProfileObject.basicProfileCreatedFor.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, basicProfileCreatedFor: {...changesInForm.basicProfileCreatedFor ,value:e.key}});
            setMyProfileObject({ ...myProfileObject, basicProfileCreatedFor: {...myProfileObject.basicProfileCreatedFor ,value:e.key}});
            handleDropDown('height');
        }else if(id === 'lastName'){
            checkErrors({
                'field': myProfileObject.lastName,
                'name': 'last name',
                'validations': myProfileObject.lastName.rules,                
                'value': e?.target?.value?.trim()
            });
            setChangesInForm({ ...changesInForm, lastName: {...changesInForm.lastName,value:e?.target?.value?.trim()} });
            setMyProfileObject({ ...myProfileObject, lastName: {...myProfileObject.lastName ,value:e?.target?.value?.trim()}});
        }else if(id === 'firstName'){
            checkErrors({
                'field': myProfileObject.firstName,
                'name': 'first name',
                'validations': myProfileObject.firstName.rules,                
                'value': e?.target?.value?.trim()
            });
            setChangesInForm({ ...changesInForm, firstName: {...changesInForm.firstName,value:e?.target?.value?.trim()} });
            setMyProfileObject({ ...myProfileObject, firstName: {...myProfileObject.firstName ,value:e?.target?.value?.trim()}});
        }else if(id === 'basicHeight'){
            checkErrors({
                'field': myProfileObject.basicHeight,
                'name': 'height',
                'validations': myProfileObject.basicHeight.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, basicHeight: {...changesInForm.basicHeight ,value:e.key}});
            setMyProfileObject({ ...myProfileObject, basicHeight: {...myProfileObject.basicHeight ,value:e.key}});
            handleDropDown('weight');
        }else if(id === 'numberOfChildrens'){
            checkErrors({
                'field': myProfileObject.basicNumberOfChildrens,
                'name': 'number of children',
                'validations': myProfileObject.basicNumberOfChildrens.rules,                
                'value': e.target.value
            });
            setChangesInForm({ ...changesInForm, basicNumberOfChildrens: {...changesInForm.basicNumberOfChildrens ,value:e.target.value}});
            setMyProfileObject({ ...myProfileObject, basicNumberOfChildrens: {...myProfileObject.basicNumberOfChildrens,value:e.target.value} });
        }else if(id === 'basicWeight'){
            checkErrors({
                'field': myProfileObject.basicWeight,
                'name': 'weight',
                'validations': myProfileObject.basicWeight.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, basicWeight: {...changesInForm.basicWeight ,value:e.key}});
            setMyProfileObject({ ...myProfileObject, basicWeight: {...myProfileObject.basicWeight ,value:e.key}});
            handleDropDown('body');
        }else if(id === 'basicBodyType'){
            checkErrors({
                'field': myProfileObject.basicBodyType,
                'name': 'body type',
                'validations': myProfileObject.basicBodyType.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, basicBodyType: {...changesInForm.basicBodyType ,value:e.key}});
            setMyProfileObject({ ...myProfileObject, basicBodyType: {...myProfileObject.basicBodyType ,value:e.key}});
            if(myProfileObject?.basicHaveAChildren?.value && myProfileObject?.basicMaritalStatus?.value && Number(myProfileObject?.basicHaveAChildren?.value) === 1 && Number(myProfileObject?.basicMaritalStatus?.value) !== 1){
                handleDropDown('numberofchildren');
            }else {
                handleDropDown('religion');
            }
        }else if(id === 'basicMaritalStatus'){
            const values = e.value ? e.value.split('+')[0] : '';
            checkErrors({
                'field': myProfileObject.basicMaritalStatus,
                'name': 'marital status',
                'validations': myProfileObject.basicMaritalStatus.rules,                
                'value': values
            });
            setChangesInForm({ ...changesInForm, basicMaritalStatus: {...changesInForm.basicMaritalStatus ,value:values}});
            setMyProfileObject({ ...myProfileObject, basicMaritalStatus: {...myProfileObject.basicMaritalStatus ,value:values}});
        }else if(id === 'basicHaveAChildren'){
            const values = e.value ? e.value.split('+')[0] : '';
            checkErrors({
                'field': myProfileObject.basicHaveAChildren,
                'name': 'have a children',
                'validations': myProfileObject.basicHaveAChildren.rules,                
                'value': values
            });
            if(Number(values) === 2){
                setChangesInForm({ ...changesInForm, basicNumberOfChildrens: {...changesInForm.basicNumberOfChildrens,value:0},basicWidowedRemark: {...changesInForm.basicWidowedRemark,value:''},basicDivorcedRemark: {...changesInForm.basicDivorcedRemark,value:''},basicAwaitingDivorcedRemark: {...changesInForm.basicAwaitingDivorcedRemark,value:''}, basicHaveAChildren: {...changesInForm.basicHaveAChildren,value:values}});
                setMyProfileObject({ ...myProfileObject, basicNumberOfChildrens: {...myProfileObject.basicNumberOfChildrens,value:0},basicWidowedRemark: {...myProfileObject.basicWidowedRemark,value:''},basicDivorcedRemark: {...myProfileObject.basicDivorcedRemark,value:''},basicAwaitingDivorcedRemark: {...myProfileObject.basicAwaitingDivorcedRemark,value:''}, basicHaveAChildren: {...myProfileObject.basicHaveAChildren,value:values}});
            }else{
                setMyProfileObject({ ...myProfileObject, basicHaveAChildren: {...myProfileObject.basicHaveAChildren,value:values}});
            }
        }else if(id === '2'){
            checkErrors({
                'field': myProfileObject.basicWidowedRemark,
                'name': 'widowed remark',
                'validations': myProfileObject.basicWidowedRemark.rules,                
                'value': e.target.value
            });
            setChangesInForm({ ...changesInForm,basicDivorcedRemark: {...changesInForm.basicDivorcedRemark,value:''},basicAwaitingDivorcedRemark: {...changesInForm.basicAwaitingDivorcedRemark,value:''},basicWidowedRemark: {...changesInForm.basicWidowedRemark,value:e.target.value} });
            setMyProfileObject({ ...myProfileObject,basicDivorcedRemark: {...myProfileObject.basicDivorcedRemark,value:''},basicAwaitingDivorcedRemark: {...myProfileObject.basicAwaitingDivorcedRemark,value:''},basicWidowedRemark: {...myProfileObject.basicWidowedRemark,value:e.target.value} });
        }else if(id === '3'){
            checkErrors({
                'field': myProfileObject.basicDivorcedRemark,
                'name': 'divorce remark',
                'validations': myProfileObject.basicDivorcedRemark.rules,                
                'value': e.target.value
            });
            setChangesInForm({ ...changesInForm,basicDivorcedRemark: {...changesInForm.basicDivorcedRemark,value:e.target.value},basicAwaitingDivorcedRemark: {...changesInForm.basicAwaitingDivorcedRemark,value:''},basicWidowedRemark: {...changesInForm.basicWidowedRemark,value:''} });
            setMyProfileObject({ ...myProfileObject,basicDivorcedRemark: {...myProfileObject.basicDivorcedRemark,value:e.target.value},basicAwaitingDivorcedRemark: {...myProfileObject.basicAwaitingDivorcedRemark,value:''},basicWidowedRemark: {...myProfileObject.basicWidowedRemark,value:''} });
        }else if(id === '4'){
            checkErrors({
                'field': myProfileObject.basicAwaitingDivorcedRemark,
                'name': 'awaiting divorce remark',
                'validations': myProfileObject.basicAwaitingDivorcedRemark.rules,                
                'value': e.target.value
            });
            setChangesInForm({ ...changesInForm,basicDivorcedRemark: {...changesInForm.basicDivorcedRemark,value:''},basicAwaitingDivorcedRemark: {...changesInForm.basicAwaitingDivorcedRemark,value:e.target.value},basicWidowedRemark: {...changesInForm.basicWidowedRemark,value:''} });
            setMyProfileObject({ ...myProfileObject,basicDivorcedRemark: {...myProfileObject.basicDivorcedRemark,value:''},basicAwaitingDivorcedRemark: {...myProfileObject.basicAwaitingDivorcedRemark,value:e.target.value},basicWidowedRemark: {...myProfileObject.basicWidowedRemark,value:''} });
        }else if(id === 'basicReligion'){
            checkErrors({
                'field': myProfileObject.basicReligion,
                'name': 'religion',
                'validations': myProfileObject.basicReligion.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, basicReligion: {...changesInForm.basicReligion ,value:e.key} })
            setMyProfileObject({ ...myProfileObject, basicReligion: {...myProfileObject.basicReligion ,value:e.key} })
            handleDropDown('caste');
        }else if(id === 'basicCaste'){
            checkErrors({
                'field': myProfileObject.basicCaste,
                'name': 'caste',
                'validations': myProfileObject.basicCaste.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, basicCaste: {...changesInForm.basicCaste ,value:e.key} })
            setMyProfileObject({ ...myProfileObject, basicCaste: {...myProfileObject.basicCaste ,value:e.key} })
            handleDropDown('mothertongue');
        }else if(id === 'basicMotherTongue'){
            checkErrors({
                'field': myProfileObject.basicMotherTongue,
                'name': 'mother tongue',
                'validations': myProfileObject.basicMotherTongue.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, basicMotherTongue: {...changesInForm.basicMotherTongue ,value:e.key} })
            setMyProfileObject({ ...myProfileObject, basicMotherTongue: {...myProfileObject.basicMotherTongue ,value:e.key} })
            handleDropDown('aboutme');
        }else if(id === 'basicDisability'){
            const values = e.value ? e.value.split('+')[0] : '';
            checkErrors({
                'field': myProfileObject.basicDisability,
                'name': 'disability',
                'validations': myProfileObject.basicDisability.rules,                
                'value': values
            });
            if(values == 2){
                setChangesInForm({ ...changesInForm, basicDisability: {...changesInForm.basicDisability ,value:values},basicDisabilityDetail: {...changesInForm.basicDisabilityDetail ,value:''}});
                setMyProfileObject({ ...myProfileObject, basicDisability: {...myProfileObject.basicDisability ,value:values},basicDisabilityDetail: {...myProfileObject.basicDisabilityDetail ,value:''}});
            }else{
                setChangesInForm({ ...changesInForm, basicDisability: {...changesInForm.basicDisability ,value:values}});
                setMyProfileObject({ ...myProfileObject, basicDisability: {...myProfileObject.basicDisability ,value:values}});
            }
        }else if(id === 'basicDisabilityDetail'){
            checkErrors({
                'field': myProfileObject.basicDisabilityDetail,
                'name': 'basicDisabilityDetail',
                'validations': myProfileObject.basicDisabilityDetail.rules,                
                'value': e?.target?.value?.trim()
            });
            setChangesInForm({ ...changesInForm, basicDisabilityDetail: {...changesInForm.basicDisabilityDetail ,value:e?.target?.value?.trim()}});
            setMyProfileObject({ ...myProfileObject, basicDisabilityDetail: {...myProfileObject.basicDisabilityDetail ,value:e?.target?.value?.trim()}});
        }else if(id === 'willingToMarray'){
            setChangesInForm({ ...changesInForm, basicWillingToMarry: {...changesInForm.basicWillingToMarry ,value:!e.value}});
            setMyProfileObject({ ...myProfileObject, basicWillingToMarry: {...myProfileObject.basicWillingToMarry,value:!e.value} });
        }else if(id === 'aboutMe'){
            checkErrors({
                'field': myProfileObject.aboutMe,
                'name': 'about me',
                'validations': myProfileObject.aboutMe.rules,                
                'value': e.value
            });
            setChangesInForm({ ...changesInForm, aboutMe: {...changesInForm.aboutMe,value:e.value}});
            setMyProfileObject({ ...myProfileObject, aboutMe: {...myProfileObject.aboutMe,value:e.value}});
            handleDropDown('citycountrystate');
        }else if(id === 'locationCountry'){
            checkErrors({
                'field': myProfileObject.locationCountry,
                'name': 'country',
                'validations': myProfileObject.locationCountry.rules,                
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, locationCountry: {...changesInForm.locationCountry,value:e.key} });
            setMyProfileObject({ ...myProfileObject, locationCountry: {...myProfileObject.locationCountry,value:e.key} });
        }else if(id === 'locationCity'){
            checkErrors({
                'field': myProfileObject.locationCity,
                'name': 'city',
                'validations': myProfileObject.locationCity.rules,                
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, locationCity: {...changesInForm.locationCity,value:e.key} });
            setMyProfileObject({ ...myProfileObject, locationCity: {...myProfileObject.locationCity,value:e.key} });
        }else if(id === 'locationState'){
            checkErrors({
                'field': myProfileObject.locationState,
                'name': 'state',
                'validations': myProfileObject.locationState.rules,                
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, locationState: {...changesInForm.locationState,value:e.key} });
            setMyProfileObject({ ...myProfileObject, locationState: {...myProfileObject.locationState,value:e.key} });
        }else if (id === 'location'){
            const addressComponents = e?.address_components;
            if(addressComponents){
                let state = '';
                let country = '';
                let city = '';
        
                // Loop through address components and extract the relevant information
                addressComponents.forEach(component => {
                    const types = component.types;
                    if (types.includes('administrative_area_level_1')) {
                        state = component.long_name; // or component.short_name
                    }
                    if (types.includes('country')) {
                        country = component.long_name; // or component.short_name
                    }
                    if (types.includes('locality') || types.includes('postal_town')) {
                        city = component.long_name; // or component.short_name
                    }
                });
                checkErrors({
                    'field': myProfileObject.locationCity,
                    'name': 'city',
                    'validations': myProfileObject.locationCity.rules,                
                    'value': city
                });
                checkErrors({
                    'field': myProfileObject.locationState,
                    'name': 'state',
                    'validations': myProfileObject.locationState.rules,                
                    'value': state
                });
                checkErrors({
                    'field': myProfileObject.locationCountry,
                    'name': 'country',
                    'validations': myProfileObject.locationCountry.rules,                
                    'value': country
                });
                setChangesInForm({ ...changesInForm, locationCity: {...changesInForm.locationCity,value:city},locationCountry: {...changesInForm.locationCountry,value:country},locationState: {...changesInForm.locationState,value:state}});
                setMyProfileObject(prevFormData => ({
                    ...prevFormData,
                    locationState: { ...myProfileObject.locationState, value: state },
                    locationCity: { ...myProfileObject.locationCity, value: city },
                    locationCountry: { ...myProfileObject.locationCountry, value: country }
                }));
            }
        }else if(id === 'locationResident'){
            checkErrors({
                'field': myProfileObject.locationResident,
                'name': 'resident',
                'validations': myProfileObject.locationResident.rules,                
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, locationResident: {...changesInForm.locationResident,value:e.key} });
            setMyProfileObject({ ...myProfileObject, locationResident: {...myProfileObject.locationResident,value:e.key} });
            handleDropDown('star');
        }else if(id === 'horoscopeStar'){
            checkErrors({
                'field': myProfileObject.horoscopeStar,
                'name': 'star',
                'validations': myProfileObject.horoscopeStar.rules,                
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, horoscopeStar: {...changesInForm.horoscopeStar,value:e.key} });
            setMyProfileObject({ ...myProfileObject, horoscopeStar: {...myProfileObject.horoscopeStar,value:e.key} });
            handleDropDown('brithdate');
        }else if(id === 'horoscopeDateOfBirth'){
            checkErrors({
                'field': myProfileObject.horoscopeDateOfBirth,
                'name': 'date of birth',
                'validations': myProfileObject.horoscopeDateOfBirth.rules,                
                'value': e[0]
            });
            setChangesInForm({ ...changesInForm, horoscopeDateOfBirth: {...changesInForm.horoscopeDateOfBirth,value:moment(e[0]).format('YYYY-MM-DD')} });
            setMyProfileObject({ ...myProfileObject, horoscopeDateOfBirth: {...myProfileObject.horoscopeDateOfBirth,value:moment(e[0]).format('YYYY-MM-DD')} });
            handleDropDown('timeofbrith');
        }else if(id === 'horoscopeTimeOfBirth'){
            checkErrors({
                'field': myProfileObject.horoscopeTimeOfBirth,
                'name': 'time of birth',
                'validations': myProfileObject.horoscopeTimeOfBirth.rules,                
                'value': e?.format('HH:mm') || ''
            });
            setChangesInForm({ ...changesInForm, horoscopeTimeOfBirth: {...changesInForm.horoscopeTimeOfBirth,value:e?.format('HH:mm') || ''} });
            setMyProfileObject({ ...myProfileObject, horoscopeTimeOfBirth: {...myProfileObject.horoscopeTimeOfBirth,value:e?.format('HH:mm') || ''} });
            handleDropDown('stateofbirth');
            if (timePickerRef3?.current) {
                timePickerRef3?.current.onVisibleChange(false);
            }
        }else if(id === 'gor'){
            checkErrors({
                'field': myProfileObject.basicGor,
                'name': 'gor',
                'validations': myProfileObject.basicGor.rules,                
                'value': e?.target?.value?.trim()
            });
            setChangesInForm({ ...changesInForm, basicGor: {...changesInForm.basicGor,value:e?.target?.value?.trim()} });
            setMyProfileObject({ ...myProfileObject, basicGor: {...myProfileObject.basicGor,value:e?.target?.value?.trim()} });
        }else if(id === 'horoscopeStateOfBirth'){
            checkErrors({
                'field': myProfileObject.horoscopeStateOfBirth,
                'name': 'state of birth',
                'validations': myProfileObject.horoscopeStateOfBirth.rules,                
                'value': e.target.value
            });
            setChangesInForm({ ...changesInForm, horoscopeStateOfBirth: {...changesInForm.horoscopeStateOfBirth,value:e.target.value} });
            setMyProfileObject({ ...myProfileObject, horoscopeStateOfBirth: {...myProfileObject.horoscopeStateOfBirth,value:e.target.value} });
        }else if(id === 'horoscopeCityOfBirth'){
            checkErrors({
                'field': myProfileObject.horoscopeCityOfBirth,
                'name': 'city of birth',
                'validations': myProfileObject.horoscopeCityOfBirth.rules,                
                'value': e.target.value
            });
            setChangesInForm({ ...changesInForm, horoscopeCityOfBirth: {...changesInForm.horoscopeCityOfBirth,value:e.target.value} });
            setMyProfileObject({ ...myProfileObject, horoscopeCityOfBirth: {...myProfileObject.horoscopeCityOfBirth,value:e.target.value} });
        }else if(id === 'horoscopeManglik'){
            const values = e.value ? e.value.split('+')[0] : '';
            checkErrors({
                'field': myProfileObject.horoscopeManglik,
                'name': 'manglik',
                'validations': myProfileObject.horoscopeManglik.rules,                
                'value': values
            });
            setChangesInForm({ ...changesInForm, horoscopeManglik: {...changesInForm.horoscopeManglik ,value:values}});
            setMyProfileObject({ ...myProfileObject, horoscopeManglik: {...myProfileObject.horoscopeManglik ,value:values}});
        }else if(id === 'horoscopeMatch'){
            const values = e.value ? e.value.split('+')[0] : '';
            checkErrors({
                'field': myProfileObject.horoscopeMatch,
                'name': 'match',
                'validations': myProfileObject.horoscopeMatch.rules,                
                'value': values
            });
            setChangesInForm({ ...changesInForm, horoscopeMatch: {...changesInForm.horoscopeMatch ,value:values}});
            setMyProfileObject({ ...myProfileObject, horoscopeMatch: {...myProfileObject.horoscopeMatch ,value:values}});
        }else if(id === 'horoscopeBelieveInHoroscope'){
            const values = e.value ? e.value.split('+')[0] : '';
            checkErrors({
                'field': myProfileObject.horoscopeBelieveInHoroscope,
                'name': 'believe',
                'validations': myProfileObject.horoscopeBelieveInHoroscope.rules,                
                'value': values
            });
            setChangesInForm({ ...changesInForm, horoscopeBelieveInHoroscope: {...changesInForm.horoscopeBelieveInHoroscope ,value:values}});
            setMyProfileObject({ ...myProfileObject, horoscopeBelieveInHoroscope: {...myProfileObject.horoscopeBelieveInHoroscope ,value:values}});
        }else if(id === 'educationHighestQualification'){
            checkErrors({
                'field': myProfileObject.educationHighestQualification,
                'name': 'highest qualification',
                'validations': myProfileObject.educationHighestQualification.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, educationHighestQualification: {...changesInForm.educationHighestQualification ,value:e.key}});
            setMyProfileObject({ ...myProfileObject, educationHighestQualification: {...myProfileObject.educationHighestQualification ,value:e.key}});
            handleDropDown('additionaleducation');
        }else if(id === 'educationAdditionalDegree'){
            checkErrors({
                'field': myProfileObject.educationAdditionalDegree,
                'name': 'additional degree',
                'validations': myProfileObject.educationAdditionalDegree.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, educationAdditionalDegree: {...changesInForm.educationAdditionalDegree ,value:e.key}});
            setMyProfileObject({ ...myProfileObject, educationAdditionalDegree: {...myProfileObject.educationAdditionalDegree ,value:e.key}});
            handleDropDown('educationsubject');
        }else if(id === 'educationSubject'){
            checkErrors({
                'field': myProfileObject.educationSubject,
                'name': 'education subject',
                'validations': myProfileObject.educationSubject.rules,                
                'value': e.target.value
            });
            setChangesInForm({ ...changesInForm, educationSubject: {...changesInForm.educationSubject,value:e.target.value} });
            setMyProfileObject({ ...myProfileObject, educationSubject: {...myProfileObject.educationSubject,value:e.target.value} });
        }else if(id === 'educationInDetail'){
            checkErrors({
                'field': myProfileObject.educationInDetail,
                'name': 'additional education detail',
                'validations': myProfileObject.educationInDetail.rules,                
                'value': e.target.value
            });
            setChangesInForm({ ...changesInForm, educationInDetail: {...changesInForm.educationInDetail,value:e.target.value} });
            setMyProfileObject({ ...myProfileObject, educationInDetail: {...myProfileObject.educationInDetail,value:e.target.value} });
        }else if(id === 'educationCollege'){
            checkErrors({
                'field': myProfileObject.educationCollege,
                'name': 'education college',
                'validations': myProfileObject.educationCollege.rules,                
                'value': e.target.value
            });
            setChangesInForm({ ...changesInForm, educationCollege: {...changesInForm.educationCollege,value:e.target.value} });
            setMyProfileObject({ ...myProfileObject, educationCollege: {...myProfileObject.educationCollege,value:e.target.value} });
        }else if(id === 'educationEmployeedIn'){
            checkErrors({
                'field': myProfileObject.educationEmployeedIn,
                'name': 'employed in',
                'validations': myProfileObject.educationEmployeedIn.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, educationEmployeedIn: {...changesInForm.educationEmployeedIn ,value:e.key}});
            setMyProfileObject({ ...myProfileObject, educationEmployeedIn: {...myProfileObject.educationEmployeedIn ,value:e.key}});
            handleDropDown('educationoccupation');
        }else if(id === 'educationOccupation'){
            checkErrors({
                'field': myProfileObject.educationOccupation,
                'name': 'education occupation',
                'validations': myProfileObject.educationOccupation.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, educationOccupation: {...changesInForm.educationOccupation ,value:e.key}});
            setMyProfileObject({ ...myProfileObject, educationOccupation: {...myProfileObject.educationOccupation ,value:e.key}});
            handleDropDown('educationOccupationDetail');
        }else if(id === 'educationOccupationDetail'){
            checkErrors({
                'field': myProfileObject.educationOccupationDetail,
                'name': 'education occupation detail',
                'validations': myProfileObject.educationOccupationDetail.rules,                
                'value': e.target.value
            });
            setChangesInForm({ ...changesInForm, educationOccupationDetail: {...changesInForm.educationOccupationDetail,value:e.target.value} });
            setMyProfileObject({ ...myProfileObject, educationOccupationDetail: {...myProfileObject.educationOccupationDetail ,value:e.target.value}});
        }else if(id === 'educationOrganization'){
            checkErrors({
                'field': myProfileObject.educationOrganization,
                'name': 'organization',
                'validations': myProfileObject.educationOrganization.rules,                
                'value': e.target.value
            });
            setChangesInForm({ ...changesInForm, educationOrganization: {...changesInForm.educationOrganization,value:e.target.value} });
            setMyProfileObject({ ...myProfileObject, educationOrganization: {...myProfileObject.educationOrganization ,value:e.target.value}});
        }else if(id === 'educationAnnualIncome'){
            checkErrors({
                'field': myProfileObject.educationAnnualIncome,
                'name': 'annual income',
                'validations': myProfileObject.educationAnnualIncome.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, educationAnnualIncome: {...changesInForm.educationAnnualIncome ,value:e.key}});
            setMyProfileObject({ ...myProfileObject, educationAnnualIncome: {...myProfileObject.educationAnnualIncome ,value:e.key}});
            handleDropDown('familyfatheroccupation');
        }else if(id === 'familyFatherOccupation'){
            checkErrors({
                'field': myProfileObject.familyFatherOccupation,
                'name': 'father occupation',
                'validations': myProfileObject.familyFatherOccupation.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, familyFatherOccupation: {...changesInForm.familyFatherOccupation ,value:e.key}});
            setMyProfileObject({ ...myProfileObject, familyFatherOccupation: {...myProfileObject.familyFatherOccupation ,value:e.key}});
            handleDropDown('familymotheroccupation');
        }else if(id === 'familyMotherOccupation'){
            checkErrors({
                'field': myProfileObject.familyMotherOccupation,
                'name': 'mother occupation',
                'validations': myProfileObject.familyMotherOccupation.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, familyMotherOccupation: {...changesInForm.familyMotherOccupation ,value:e.key}});
            setMyProfileObject({ ...myProfileObject, familyMotherOccupation: {...myProfileObject.familyMotherOccupation ,value:e.key}});
            handleDropDown('sister');
        }else if(id === 'familySister'){
            checkErrors({
                'field': myProfileObject.familySister,
                'name': 'family sister',
                'validations': myProfileObject.familySister.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, familySister: {...changesInForm.familySister ,value:e.key},familyMarriedSister: {...changesInForm.familyMarriedSister ,value:''}});
            setMyProfileObject({ ...myProfileObject, familySister: {...myProfileObject.familySister ,value:e.key},familyMarriedSister: {...myProfileObject.familyMarriedSister ,value:''}});
            handleDropDown('sistermarried');
        }else if(id === 'familyMarriedSister'){
            checkErrors({
                'field': myProfileObject.familyMarriedSister,
                'name': 'family married sister',
                'validations': myProfileObject.familyMarriedSister.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, familyMarriedSister: {...changesInForm.familyMarriedSister ,value:e.key}});
            setMyProfileObject({ ...myProfileObject, familyMarriedSister: {...myProfileObject.familyMarriedSister ,value:e.key}});
            handleDropDown('brother');
        }else if(id === 'familyBrother'){
            checkErrors({
                'field': myProfileObject.familyBrother,
                'name': 'family brother',
                'validations': myProfileObject.familyBrother.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, familyBrother: {...changesInForm.familyBrother ,value:e.key},familyMarriedBrother: {...changesInForm.familyMarriedBrother ,value:''}});
            setMyProfileObject({ ...myProfileObject, familyBrother: {...myProfileObject.familyBrother ,value:e.key},familyMarriedBrother: {...myProfileObject.familyMarriedBrother ,value:''}});
            handleDropDown('brothermarried');
        }else if(id === 'familyMarriedBrother'){
            checkErrors({
                'field': myProfileObject.familyMarriedBrother,
                'name': 'family married brother',
                'validations': myProfileObject.familyMarriedBrother.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, familyMarriedBrother: {...changesInForm.familyMarriedBrother ,value:e.key}});
            setMyProfileObject({ ...myProfileObject, familyMarriedBrother: {...myProfileObject.familyMarriedBrother ,value:e.key}});
            handleDropDown('familystatus');
        }else if(id === 'familyStatus'){
            checkErrors({
                'field': myProfileObject.familyStatus,
                'name': 'family status',
                'validations': myProfileObject.familyStatus.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, familyStatus: {...changesInForm.familyStatus ,value:e.key}});
            setMyProfileObject({ ...myProfileObject, familyStatus: {...myProfileObject.familyStatus ,value:e.key}});
            handleDropDown('familytype');
        }else if(id === 'familyType'){
            checkErrors({
                'field': myProfileObject.familyType,
                'name': 'family type',
                'validations': myProfileObject.familyType.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, familyType: {...changesInForm.familyType ,value:e.key}});
            setMyProfileObject({ ...myProfileObject, familyType: {...myProfileObject.familyType ,value:e.key}});
            handleDropDown('familyvalues');
        }else if(id === 'familyValues'){
            checkErrors({
                'field': myProfileObject.familyValues,
                'name': 'family values',
                'validations': myProfileObject.familyValues.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, familyValues: {...changesInForm.familyValues ,value:e.key}});
            setMyProfileObject({ ...myProfileObject, familyValues: {...myProfileObject.familyValues ,value:e.key}});
            handleDropDown('annualincome');
        }else if(id === 'familyIncome'){
            checkErrors({
                'field': myProfileObject.familyIncome,
                'name': 'family Income',
                'validations': myProfileObject.familyIncome.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, familyIncome: {...changesInForm.familyIncome ,value:e.key}});
            setMyProfileObject({ ...myProfileObject, familyIncome: {...myProfileObject.familyIncome ,value:e.key}});
            handleDropDown('familyliving');
        }else if(id === 'familyLiving'){
            const addressComponents = e?.address_components;
            let state = '';
            let country = '';
            let city = '';
    
            // Loop through address components and extract the relevant information
            addressComponents?.forEach(component => {
                const types = component.types;
                if (types.includes('administrative_area_level_1')) {
                    state = component.long_name; // or component.short_name
                }
                if (types.includes('country')) {
                    country = component.long_name; // or component.short_name
                }
                if (types.includes('locality') || types.includes('postal_town')) {
                    city = component.long_name; // or component.short_name
                }
            });
            checkErrors({
                'field': myProfileObject.familyLivingCountry,
                'name': 'family country',
                'validations': myProfileObject.familyLivingCountry.rules,
                'value': country
            });
            checkErrors({
                'field': myProfileObject.familyLivingState,
                'name': 'family state',
                'validations': myProfileObject.familyLivingState.rules,
                'value': state
            });
            checkErrors({
                'field': myProfileObject.familyLivingIn,
                'name': 'family livingIn',
                'validations': myProfileObject.familyLivingIn.rules,
                'value': city
            });
            setChangesInForm({ ...changesInForm, familyLivingIn: {...changesInForm.familyLivingIn,value:city},familyLivingCountry: {...changesInForm.familyLivingCountry,value:country},familyLivingState: {...changesInForm.familyLivingState,value:state}});
            setMyProfileObject(prevFormData => ({
                ...prevFormData,
                familyLivingState: { ...myProfileObject.familyLivingState, value: state },
                familyLivingIn: { ...myProfileObject.familyLivingIn, value: city },
                familyLivingCountry: { ...myProfileObject.familyLivingCountry, value: country }
            }));
        }else if(id === 'familyLiveWithFamily'){
            const values = e.value ? e.value.split('+')[0] : '';
            checkErrors({
                'field': myProfileObject.familyLiveWithFamily,
                'name': 'live with family',
                'validations': myProfileObject.familyLiveWithFamily.rules,                
                'value': values
            });
            setChangesInForm({ ...changesInForm, familyLiveWithFamily: {...changesInForm.familyLiveWithFamily ,value:values}});
            setMyProfileObject({ ...myProfileObject, familyLiveWithFamily: {...myProfileObject.familyLiveWithFamily ,value:values}});
        }else if(id === 'lifeStyleEatingHabits'){
            checkErrors({
                'field': myProfileObject.lifeStyleEatingHabits,
                'name': 'eating habits',
                'validations': myProfileObject.lifeStyleEatingHabits.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, lifeStyleEatingHabits: {...changesInForm.lifeStyleEatingHabits ,value:e.key}});
            setMyProfileObject({ ...myProfileObject, lifeStyleEatingHabits: {...myProfileObject.lifeStyleEatingHabits ,value:e.key}});
            handleDropDown('smokinghabits');
        }else if(id === 'lifeStyleSmokingHabits'){
            checkErrors({
                'field': myProfileObject.lifeStyleSmokingHabits,
                'name': 'smoking habits',
                'validations': myProfileObject.lifeStyleSmokingHabits.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, lifeStyleSmokingHabits: {...changesInForm.lifeStyleSmokingHabits ,value:e.key}});
            setMyProfileObject({ ...myProfileObject, lifeStyleSmokingHabits: {...myProfileObject.lifeStyleSmokingHabits ,value:e.key}});
            handleDropDown('drinkinghabits');
        }else if(id === 'lifeStyleDrinkingHabits'){
            checkErrors({
                'field': myProfileObject.lifeStyleDrinkingHabits,
                'name': 'drinking habits',
                'validations': myProfileObject.lifeStyleDrinkingHabits.rules,
                'value': e.key
            });
            setChangesInForm({ ...changesInForm, lifeStyleDrinkingHabits: {...changesInForm.lifeStyleDrinkingHabits ,value:e.key}});
            setMyProfileObject({ ...myProfileObject, lifeStyleDrinkingHabits: {...myProfileObject.lifeStyleDrinkingHabits ,value:e.key}});
            handleDropDown('languagesknown');
        }else if(id === 'lifeStyleOwnsHouse'){
            const values = e.value ? e.value.split('+')[0] : '';
            checkErrors({
                'field': myProfileObject.lifeStyleOwnsHouse,
                'name': 'owns house',
                'validations': myProfileObject.lifeStyleOwnsHouse.rules,                
                'value': values
            });
            setChangesInForm({ ...changesInForm, lifeStyleOwnsHouse: {...changesInForm.lifeStyleOwnsHouse ,value:values}});
            setMyProfileObject({ ...myProfileObject, lifeStyleOwnsHouse: {...myProfileObject.lifeStyleOwnsHouse ,value:values}});
        }else if(id === 'lifeStyleOwnsCar'){
            const values = e.value ? e.value.split('+')[0] : '';
            checkErrors({
                'field': myProfileObject.lifeStyleOwnsCar,
                'name': 'owns car',
                'validations': myProfileObject.lifeStyleOwnsCar.rules,                
                'value': values
            });
            setChangesInForm({ ...changesInForm, lifeStyleOwnsCar: {...changesInForm.lifeStyleOwnsCar ,value:values}});
            setMyProfileObject({ ...myProfileObject, lifeStyleOwnsCar: {...myProfileObject.lifeStyleOwnsCar ,value:values}});
        }else if(id === 'lifeStyleLanguages'){
            const value = e.map(e => e.key);
            checkErrors({
                'field': myProfileObject.lifeStyleLanguages,
                'name': 'drinking habits',
                'validations': myProfileObject.lifeStyleLanguages.rules,
                'value': value
            });
            setChangesInForm({ ...changesInForm, lifeStyleLanguages: {...changesInForm.lifeStyleLanguages ,value:value}});
            setMyProfileObject({ ...myProfileObject, lifeStyleLanguages: {...myProfileObject.lifeStyleLanguages ,value:value}});
        }else if(id === 'interestHobbies'){
            const selected = [...myProfileObject['interestHobbies'].value]; // Copy current array
    
            // Toggle selection
            if (selected.includes(e.key)) {
                // If already selected, remove it
                selected.splice(selected.indexOf(e.key), 1);
                calculateTotalLength(-1);
            } else {
                // If not selected, add it
                selected.push(e.key);
                calculateTotalLength(1);
            }
            checkErrors({
                'field': myProfileObject.interestHobbies,
                'name': 'hobbies',
                'validations': myProfileObject.interestHobbies.rules,
                'value': selected
            });
            setChangesInForm({ ...changesInForm, interestHobbies: {...changesInForm.interestHobbies ,value:selected}});
            setMyProfileObject({
                ...myProfileObject,
                ['interestHobbies']: { ...myProfileObject['interestHobbies'], value: selected }
            });
        }else if(id === 'interest'){
            const selected = [...myProfileObject['interest'].value]; // Copy current array
    
            // Toggle selection
            if (selected.includes(e.key)) {
                // If already selected, remove it
                selected.splice(selected.indexOf(e.key), 1);
                calculateTotalLength(-1);
            } else {
                // If not selected, add it
                selected.push(e.key);
                calculateTotalLength(1);
            }
            checkErrors({
                'field': myProfileObject.interest,
                'name': 'interest',
                'validations': myProfileObject.interest.rules,
                'value': selected
            });
            setChangesInForm({ ...changesInForm, interest: {...changesInForm.interest ,value:selected}});
            setMyProfileObject({
                ...myProfileObject,
                ['interest']: { ...myProfileObject['interest'], value: selected }
            });
        }else if(id === 'interestMusic'){
            const selected = [...myProfileObject['interestMusic'].value]; // Copy current array
    
            // Toggle selection
            if (selected.includes(e.key)) {
                // If already selected, remove it
                selected.splice(selected.indexOf(e.key), 1);
                calculateTotalLength(-1);
            } else {
                // If not selected, add it
                selected.push(e.key);
                calculateTotalLength(1);
            }
            checkErrors({
                'field': myProfileObject.interestMusic,
                'name': 'music',
                'validations': myProfileObject.interestMusic.rules,
                'value': selected
            });
            setChangesInForm({ ...changesInForm, interestMusic: {...changesInForm.interestMusic ,value:selected}});
            setMyProfileObject({
                ...myProfileObject,
                ['interestMusic']: { ...myProfileObject['interestMusic'], value: selected }
            });
        }else if(id === 'interestFavouriteRead'){
            const selected = [...myProfileObject['interestFavouriteRead'].value]; // Copy current array
    
            // Toggle selection
            if (selected.includes(e.key)) {
                // If already selected, remove it
                selected.splice(selected.indexOf(e.key), 1);
                calculateTotalLength(-1);
            } else {
                // If not selected, add it
                selected.push(e.key);
                calculateTotalLength(1);
            }
            checkErrors({
                'field': myProfileObject.interestFavouriteRead,
                'name': 'favourite read',
                'validations': myProfileObject.interestFavouriteRead.rules,
                'value': selected
            });
            setChangesInForm({ ...changesInForm, interestFavouriteRead: {...changesInForm.interestFavouriteRead ,value:selected}});
            setMyProfileObject({
                ...myProfileObject,
                ['interestFavouriteRead']: { ...myProfileObject['interestFavouriteRead'], value: selected }
            });
        }else if(id === 'interestDressStyle'){
            const selected = [...myProfileObject['interestDressStyle'].value]; // Copy current array
    
            // Toggle selection
            if (selected.includes(e.key)) {
                // If already selected, remove it
                selected.splice(selected.indexOf(e.key), 1);
                calculateTotalLength(-1);
            } else {
                // If not selected, add it
                selected.push(e.key);
                calculateTotalLength(1);
            }
            checkErrors({
                'field': myProfileObject.interestDressStyle,
                'name': 'dress style',
                'validations': myProfileObject.interestDressStyle.rules,
                'value': selected
            });
            setChangesInForm({ ...changesInForm, interestDressStyle: {...changesInForm.interestDressStyle ,value:selected}});
            setMyProfileObject({
                ...myProfileObject,
                ['interestDressStyle']: { ...myProfileObject['interestDressStyle'], value: selected }
            });
        }else if(id === 'interestFavouriteTVShows'){
            const selected = [...myProfileObject['interestFavouriteTVShows'].value]; // Copy current array
    
            // Toggle selection
            if (selected.includes(e.key)) {
                // If already selected, remove it
                selected.splice(selected.indexOf(e.key), 1);
                calculateTotalLength(-1);
            } else {
                // If not selected, add it
                selected.push(e.key);
                calculateTotalLength(1);
            }
            checkErrors({
                'field': myProfileObject.interestFavouriteTVShows,
                'name': 'favourite tv shows',
                'validations': myProfileObject.interestFavouriteTVShows.rules,
                'value': selected
            });
            setChangesInForm({ ...changesInForm, interestFavouriteTVShows: {...changesInForm.interestFavouriteTVShows ,value:selected}});
            setMyProfileObject({
                ...myProfileObject,
                ['interestFavouriteTVShows']: { ...myProfileObject['interestFavouriteTVShows'], value: selected }
            });
        }else if(id === 'interestPreferredMovies'){
            const selected = [...myProfileObject['interestPreferredMovies'].value]; // Copy current array
    
            // Toggle selection
            if (selected.includes(e.key)) {
                // If already selected, remove it
                selected.splice(selected.indexOf(e.key), 1);
                calculateTotalLength(-1);
            } else {
                // If not selected, add it
                selected.push(e.key);
                calculateTotalLength(1);
            }
            checkErrors({
                'field': myProfileObject.interestPreferredMovies,
                'name': 'preferred movies',
                'validations': myProfileObject.interestPreferredMovies.rules,
                'value': selected
            });
            setChangesInForm({ ...changesInForm, interestPreferredMovies: {...changesInForm.interestPreferredMovies ,value:selected}});
            setMyProfileObject({
                ...myProfileObject,
                ['interestPreferredMovies']: { ...myProfileObject['interestPreferredMovies'], value: selected }
            });
        }else if(id === 'interestSportsFitness'){
            const selected = [...myProfileObject['interestSportsFitness'].value]; // Copy current array
    
            // Toggle selection
            if (selected.includes(e.key)) {
                // If already selected, remove it
                selected.splice(selected.indexOf(e.key), 1);
                calculateTotalLength(-1);
            } else {
                // If not selected, add it
                selected.push(e.key);
                calculateTotalLength(1);
            }
            checkErrors({
                'field': myProfileObject.interestSportsFitness,
                'name': 'sports fitness',
                'validations': myProfileObject.interestSportsFitness.rules,
                'value': selected
            });
            setChangesInForm({ ...changesInForm, interestSportsFitness: {...changesInForm.interestSportsFitness ,value:selected}});
            setMyProfileObject({
                ...myProfileObject,
                ['interestSportsFitness']: { ...myProfileObject['interestSportsFitness'], value: selected }
            });
        }else if(id === 'interestFavouriteCusine'){
            const selected = [...myProfileObject['interestFavouriteCusine'].value]; // Copy current array
    
            // Toggle selection
            if (selected.includes(e.key)) {
                // If already selected, remove it
                selected.splice(selected.indexOf(e.key), 1);
                calculateTotalLength(-1);
            } else {
                // If not selected, add it
                selected.push(e.key);
                calculateTotalLength(1);
            }
            checkErrors({
                'field': myProfileObject.interestFavouriteCusine,
                'name': 'favourite cuisine',
                'validations': myProfileObject.interestFavouriteCusine.rules,
                'value': selected
            });
            setChangesInForm({ ...changesInForm, interestFavouriteCusine: {...changesInForm.interestFavouriteCusine ,value:selected}});
            setMyProfileObject({
                ...myProfileObject,
                ['interestFavouriteCusine']: { ...myProfileObject['interestFavouriteCusine'], value: selected }
            });
        }else if(id === 'interestFavouriteVacationDestination'){
            const selected = [...myProfileObject['interestFavouriteVacationDestination'].value]; // Copy current array
            // Toggle selection
            if (selected.includes(e.key)) {
                // If already selected, remove it
                selected.splice(selected.indexOf(e.key), 1);
                calculateTotalLength(-1);
            } else {
                // If not selected, add it
                selected.push(e.key);
                calculateTotalLength(1);
            }
            checkErrors({
                'field': myProfileObject.interestFavouriteVacationDestination,
                'name': 'favourite vacation destination',
                'validations': myProfileObject.interestFavouriteVacationDestination.rules,
                'value': selected
            });
            setChangesInForm({ ...changesInForm, interestFavouriteVacationDestination: {...changesInForm.interestFavouriteVacationDestination ,value:selected}});
            setMyProfileObject({
                ...myProfileObject,
                ['interestFavouriteVacationDestination']: { ...myProfileObject['interestFavouriteVacationDestination'], value: selected }
            });
        }
    };
    const calculateTotalLength = (value) => {
        setTotalLength(prev => prev + (value))
        return;
    };
    useEffect(() => {
        const interestHobbiesLength = myProfileObject.interestHobbies.value && myProfileObject.interestHobbies.value.length ? myProfileObject.interestHobbies.value.length : 0;
        const interestLength = myProfileObject.interest.value && myProfileObject.interest.value.length ? myProfileObject.interest.value.length : 0;
        const interestMusicLength = myProfileObject.interestMusic.value && myProfileObject.interestMusic.value.length ? myProfileObject.interestMusic.value.length : 0;
        const interestFavouriteRead = myProfileObject.interestFavouriteRead.value && myProfileObject.interestFavouriteRead.value.length ? myProfileObject.interestFavouriteRead.value.length : 0;
        const interestDressStyle = myProfileObject.interestDressStyle.value && myProfileObject.interestDressStyle.value.length ? myProfileObject.interestDressStyle.value.length : 0;
        const interestFavouriteTVShows = myProfileObject.interestFavouriteTVShows.value && myProfileObject.interestFavouriteTVShows.value.length ? myProfileObject.interestFavouriteTVShows.value.length : 0;
        const interestPreferredMovies = myProfileObject.interestPreferredMovies.value && myProfileObject.interestPreferredMovies.value.length ? myProfileObject.interestPreferredMovies.value.length : 0;
        const interestSportsFitness = myProfileObject.interestSportsFitness.value && myProfileObject.interestSportsFitness.value.length ? myProfileObject.interestSportsFitness.value.length : 0;
        const interestFavouriteCusine = myProfileObject.interestFavouriteCusine.value && myProfileObject.interestFavouriteCusine.value.length ? myProfileObject.interestFavouriteCusine.value.length : 0;
        const interestFavouriteVacationDestination = myProfileObject.interestFavouriteVacationDestination.value && myProfileObject.interestFavouriteVacationDestination.value.length ? myProfileObject.interestFavouriteVacationDestination.value.length : 0;
        const totalLengths = interestHobbiesLength+interestLength + interestMusicLength +interestFavouriteRead +interestDressStyle +interestFavouriteTVShows +interestPreferredMovies +interestSportsFitness +interestFavouriteCusine+interestFavouriteVacationDestination;
        setTotalLength(totalLengths);
    },[]);
    const handleCancel = (event) => {
        setChangesInForm({});
        setMyProfileObject(prev => ({
            ...prev,
            basicProfileCreatedFor: {value: userDetails?.basicProfileCreatedFor ? userDetails?.basicProfileCreatedFor :'' ,rules:"required",name: "profile for",error: ""},
            firstName: {value: userDetails?.firstName ? userDetails?.firstName :'' ,rules:"required|max:50",name: "first name",error: ""},
            lastName: {value: userDetails?.lastName ? userDetails?.lastName :'' ,rules:"required|max:50",name: "last name",error: ""},
            basicAge: {value: userDetails?.basicAge ? userDetails?.basicAge :'-' ,rules:"required",name: "basic age",error: ""},
            basicHeight:{value: userDetails?.basicHeight ? userDetails?.basicHeight : '',rules:"required",name: "height",error: ""},
            basicGor: {value: userDetails?.basicGor ? userDetails?.basicGor : '',rules:"required|min:3|max:100",name: "gor",error: ""},
            basicWeight:{value: userDetails?.basicWeight ? userDetails?.basicWeight : '',rules:"",name: "weight",error: ""},
            basicBodyType:{value: userDetails?.basicBodyType ? userDetails?.basicBodyType : '',rules:"",name: "body type",error: ""},
            basicMaritalStatus:{value: userDetails?.basicMaritalStatus ? userDetails?.basicMaritalStatus : '',rules:"required",name: "marital status",error: ""},
            basicHaveAChildren:{value: userDetails?.basicHaveAChildren ? userDetails?.basicHaveAChildren : null,rules:"",name: "have a children",error: ""},
            basicNumberOfChildrens:{value: userDetails?.basicNumberOfChildrens ? userDetails?.basicNumberOfChildrens : 0,rules:"regex:^[0-9]+$",name: "number of children",error: ""},
            basicAwaitingDivorcedRemark:{value: userDetails?.basicAwaitingDivorcedRemark ? userDetails?.basicAwaitingDivorcedRemark : '',rules:"",name: "awaiting divorce remark",error: ""},
            basicDivorcedRemark:{value: userDetails?.basicDivorcedRemark ? userDetails?.basicDivorcedRemark : '',rules:"",name: "divorce remark",error: ""},
            basicWidowedRemark:{value: userDetails?.basicWidowedRemark ? userDetails?.basicWidowedRemark : '',rules:"",name: "widowed remark",error: ""},
            basicReligion: {value: userDetails?.basicReligion ? userDetails?.basicReligion : '',rules:"required",name: "religion",error: ""},
            basicCaste: {value: userDetails?.basicCaste ? userDetails?.basicCaste : '',rules:"required",name: "caste",error: ""},
            basicMotherTongue: {value: userDetails?.basicMotherTongue ? userDetails?.basicMotherTongue :'' ,rules:"required",name: "mother tongue",error: ""},
            basicDisability:{value: userDetails?.basicDisability ? userDetails?.basicDisability : '',rules:"required",name: "disability",error: ""},
            basicDisabilityDetail:{value: userDetails?.basicDisabilityDetail ? userDetails?.basicDisabilityDetail : '',rules:"",name: "basicDisabilityDetail",error: ""},
            basicWillingToMarry:{value: userDetails?.basicWillingToMarry || false,rules:"",name: "marry",error: ""},
            aboutMe:{value: userDetails?.aboutMe ? userDetails?.aboutMe : '',rules:"required|min:3|max:500",name: "about me",error: ""},
            locationCountry:{value: userDetails?.locationCountry ? userDetails?.locationCountry : '',rules:"required",name: "country",error: ""},
            locationCity:{value: userDetails?.locationCity ? userDetails?.locationCity : '',rules:"required",name: "city",error: ""},
            locationState:{value: userDetails?.locationState ? userDetails?.locationState : '',rules:"required",name: "state",error: ""},
            locationResident:{value: userDetails?.locationResident ? userDetails?.locationResident : '',rules:"",name: "resident",error: ""},
            horoscopeStar:{value: userDetails?.horoscopeStar ? userDetails?.horoscopeStar : '',rules:"",name: "star",error: ""},
            horoscopeDateOfBirth:{value: userDetails?.horoscopeDateOfBirth ? userDetails?.horoscopeDateOfBirth : '',rules:"required",name: "date of birth",error: ""},
            horoscopeTimeOfBirth:{value: userDetails?.horoscopeTimeOfBirth ? userDetails?.horoscopeTimeOfBirth : '',rules:"",name: "time of birth",error: ""},
            horoscopeStateOfBirth:{value: userDetails?.horoscopeStateOfBirth ? userDetails?.horoscopeStateOfBirth : '',rules:"",name: "state of birth",error: ""},
            horoscopeCityOfBirth:{value: userDetails?.horoscopeCityOfBirth ? userDetails?.horoscopeCityOfBirth : '',rules:"",name: "city of birth",error: ""},
            horoscopeManglik:{value: userDetails?.horoscopeManglik ? userDetails?.horoscopeManglik : '',rules:"required",name: "manglik",error: ""},
            horoscopeBelieveInHoroscope:{value: userDetails?.horoscopeBelieveInHoroscope ? userDetails?.horoscopeBelieveInHoroscope : '',rules:"",name: "believe",error: ""},
            horoscopeMatch:{value: userDetails?.horoscopeMatch ? userDetails?.horoscopeMatch : '',rules:"",name: "match",error: ""},
            educationHighestQualification:{value: userDetails?.educationHighestQualification ? userDetails?.educationHighestQualification : '',rules:"required",name: "highest qualification",error: ""},
            educationAdditionalDegree:{value: userDetails?.educationAdditionalDegree ? userDetails?.educationAdditionalDegree : '',rules:"",name: "additional degree",error: ""},
            educationSubject:{value: userDetails?.educationSubject ? userDetails?.educationSubject : '',rules:"",name: "education subject",error: ""},
            educationInDetail:{value: userDetails?.educationInDetail ? userDetails?.educationInDetail : '',rules:"",name: "additional education detail",error: ""},
            educationCollege:{value: userDetails?.educationCollege ? userDetails?.educationCollege : '',rules:"",name: "education college",error: ""},
            educationEmployeedIn:{value: userDetails?.educationEmployeedIn ? userDetails?.educationEmployeedIn : '',rules:"",name: "employed in",error: ""},
            educationOccupation:{value: userDetails?.educationOccupation ? userDetails?.educationOccupation : '',rules:"required",name: "education occupation",error: ""},
            educationOccupationDetail:{value: userDetails?.educationOccupationDetail ? userDetails?.educationOccupationDetail : '',rules:"",name: "education occupation detail",error: ""},
            educationOrganization:{value: userDetails?.educationOrganization ? userDetails?.educationOrganization : '',rules:"",name: "organization",error: ""},
            educationAnnualIncome:{value: userDetails?.educationAnnualIncome ? userDetails?.educationAnnualIncome : '',rules:"",name: "annual income",error: ""},
            familyFatherOccupation:{value: userDetails?.familyFatherOccupation ? userDetails?.familyFatherOccupation : '',rules:"",name: "father occupation",error: ""},
            familyMotherOccupation:{value: userDetails?.familyMotherOccupation ? userDetails?.familyMotherOccupation : '',rules:"",name: "mother occupation",error: ""},
            familySister:{value: userDetails?.familySister ? userDetails?.familySister : null,rules:"",name: "family sister",error: ""},
            familyMarriedSister:{value: userDetails?.familyMarriedSister ? userDetails?.familyMarriedSister : null,rules:"",name: "family married sister",error: ""},
            familyBrother:{value: userDetails?.familyBrother ? userDetails?.familyBrother : null,rules:"",name: "family brother",error: ""},
            familyMarriedBrother:{value: userDetails?.familyMarriedBrother ? userDetails?.familyMarriedBrother : null,rules:"",name: "family married brother",error: ""},
            familyStatus:{value: userDetails?.familyStatus ? userDetails?.familyStatus : '',rules:"required",name: "family status",error: ""},
            familyType:{value: userDetails?.familyType ? userDetails?.familyType : '',rules:"",name: "family type",error: ""},
            familyValues:{value: userDetails?.familyValues ? userDetails?.familyValues : '',rules:"required",name: "family values",error: ""},
            familyIncome:{value: userDetails?.familyIncome ? userDetails?.familyIncome : '',rules:"",name: "family Income",error: ""},
            familyLivingState:{value: userDetails?.familyLivingState ? userDetails?.familyLivingState : '',rules:"",name: "family state",error: ""},
            familyLivingCountry:{value: userDetails?.familyLivingCountry ? userDetails?.familyLivingCountry : '',rules:"",name: "family country",error: ""},
            familyLivingIn:{value: userDetails?.familyLivingIn ? userDetails?.familyLivingIn : '',rules:"",name: "family livingIn",error: ""},
            familyLiveWithFamily:{value: userDetails?.familyLiveWithFamily ? userDetails?.familyLiveWithFamily : '',rules:"",name: "live with family",error: ""},
            lifeStyleEatingHabits:{value: userDetails?.lifeStyleEatingHabits ? userDetails?.lifeStyleEatingHabits : '',rules:"",name: "eating habits",error: ""},
            lifeStyleSmokingHabits:{value: userDetails?.lifeStyleSmokingHabits ? userDetails?.lifeStyleSmokingHabits : '',rules:"",name: "smoking habits",error: ""},
            lifeStyleDrinkingHabits:{value: userDetails?.lifeStyleDrinkingHabits ? userDetails?.lifeStyleDrinkingHabits : '',rules:"",name: "drinking habits",error: ""},
            lifeStyleOwnsHouse:{value: userDetails?.lifeStyleOwnsHouse ? userDetails?.lifeStyleOwnsHouse : '',rules:"",name: "owns house",error: ""},
            lifeStyleOwnsCar:{value: userDetails?.lifeStyleOwnsCar ? userDetails?.lifeStyleOwnsCar : '',rules:"",name: "owns car",error: ""},
            lifeStyleLanguages:{value: userDetails?.lifeStyleLanguages ? userDetails?.lifeStyleLanguages : '',rules:"",name: "life style languages",error: ""},
            interestFavouriteVacationDestination:{value: userDetails?.interestFavouriteVacationDestination && userDetails?.interestFavouriteVacationDestination?.length ? userDetails?.interestFavouriteVacationDestination : [],rules:"",name: "favourite vacation destination",error: ""},
            interestFavouriteCusine:{value: userDetails?.interestFavouriteCusine && userDetails?.interestFavouriteCusine?.length ? userDetails?.interestFavouriteCusine : [],rules:"",name: "favourite cuisine",error: ""},
            interestSportsFitness:{value: userDetails?.interestSportsFitness && userDetails?.interestSportsFitness?.length ? userDetails?.interestSportsFitness : [],rules:"",name: "sports fitness",error: ""},
            interestPreferredMovies:{value: userDetails?.interestPreferredMovies && userDetails?.interestPreferredMovies?.length ? userDetails?.interestPreferredMovies : [],rules:"",name: "preferred movies",error: ""},
            interestFavouriteTVShows:{value: userDetails?.interestFavouriteTVShows && userDetails?.interestFavouriteTVShows?.length ? userDetails?.interestFavouriteTVShows : [],rules:"",name: "favourite tv shows",error: ""},
            interestDressStyle:{value: userDetails?.interestDressStyle && userDetails?.interestDressStyle?.length ? userDetails?.interestDressStyle : [],rules:"",name: "dress style",error: ""},
            interestFavouriteRead:{value: userDetails?.interestFavouriteRead && userDetails?.interestFavouriteRead?.length ? userDetails?.interestFavouriteRead : [],rules:"",name: "favourite read",error: ""},
            interestMusic:{value: userDetails?.interestMusic && userDetails?.interestMusic?.length ? userDetails?.interestMusic : [],rules:"",name: "music",error: ""},
            interest:{value: userDetails?.interest && userDetails?.interest?.length ? userDetails?.interest : [],rules:"",name: "interest",error: ""},
            interestHobbies:{value: userDetails?.interestHobbies && userDetails?.interestHobbies?.length ? userDetails?.interestHobbies : [],rules:"",name: "hobbies",error: ""}
        }));
        props.onClick(event,'cancel',{});
    };
    const handleSubmit = (event) => {
        setMyProfileObject({...myProfileObject});        
        checkAllFields(myProfileObject).then((valid)=>{
            if(valid && totalLength <= 5){
                props.onClick(event,'save',myProfileObject);
            }
        });
    };
    const handleKeyPress = (event, nextInputId) => {
        focusNextInput(event, nextInputId);
    };
    const handleDropDown = (nextInputId) => {
        focusOnDropdown(nextInputId);
    };
    useEffect(() => {
        if(totalLength === 6){
            toast.error('The interest & more value should be less than 5 value', {
                position: 'top-right',
            });
        }
    },[totalLength])
    return(
        <div>
            <h2 className="text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular text-[30px] md:text-[34px] lg:text-[48px] mb-[15px] lg:leading-[57.6px] md:leading-[30px] leading-[normal]">Edit My Profile</h2>
            <div>
                <div className="bg-[#f0e1b9] rounded-[12px] pt-[20px] pb-[10px] mb-[30px]">
                    <h4 className="px-[25px] text-[#ed1c24] ms:leading-[1.2] leading-[normal] font-family-ZCOOLXiaoWei-Regular ms:text-[30px] text-[24px]">Basic Info</h4>
                    <div className="grid grid-cols-1 md:grid-cols-2 ms:pt-[20px] ms:pb-[10px] ms:px-[12px] pt-[20px] pb-[10px] px-[0px]">
                    <div className="px-[15px]">
                            <CustomInput
                                placeHolder='Enter First Name'
                                label="First Name *"
                                inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[41px] mb-[12px] mt-[5px] border border-[#ced4da] rounded-[10px]'}
                                class={'leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px] block'}
                                type="text"
                                modelValue={myProfileObject?.firstName?.value || ''}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                onChange={(_, e) => handleSelect(e,'firstName')}
                                onKeyPress={(event) => handleKeyPress(event, 'lastname')}
                            />
                            {myProfileObject?.firstName?.error &&
                                <small className="leading-[normal] mt-[-5px] mb-[5px] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{myProfileObject.firstName.error}</small>
                            }
                        </div>
                        <div className="px-[15px]">
                            <CustomInput
                                inputId="lastname"
                                placeHolder='Enter Last Name'
                                label="Last Name *"
                                inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[41px] mb-[12px] mt-[5px] border border-[#ced4da] rounded-[10px]'}
                                class={'leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px] block'}
                                type="text"
                                modelValue={myProfileObject?.lastName?.value || ''}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                onChange={(_, e) => handleSelect(e,'lastName')}
                                onKeyPress={(event) => handleKeyPress(event, 'profile')}
                            />
                            {myProfileObject?.lastName?.error &&
                                <small className="leading-[normal] mt-[-5px] mb-[5px] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{myProfileObject.lastName.error}</small>
                            }
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Profile Created For *</h5>
                            <DropDown
                                options={dropDown?.['profileCreatorFor']?.option}
                                onUpdate={(e) => handleSelect(e,'profileCreatorFor')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.basicProfileCreatedFor?.value ? [Number(myProfileObject.basicProfileCreatedFor.value)] : []}
                                selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative border border-[#ced4da] rounded-[10px]'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="profile"
                            />
                            {myProfileObject?.basicProfileCreatedFor?.error &&
                                <small className="leading-[normal] mt-[-5px] mb-[5px] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{myProfileObject.basicProfileCreatedFor.error}</small>
                            }
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Age</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{age} Years</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Height *</h5>
                            <DropDown
                                isInputSearch={true}
                                options={dropDown?.['height']?.option}
                                onUpdate={(e) => handleSelect(e,'basicHeight')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.basicHeight?.value ? [myProfileObject.basicHeight.value] : []}
                                selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative border border-[#ced4da] rounded-[10px]'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="height"
                            />
                            {myProfileObject?.basicHeight?.error &&
                                <small className="leading-[normal] mt-[-5px] mb-[5px] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{myProfileObject.basicHeight.error}</small>
                            }
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Weight</h5>
                            <DropDown
                                isInputSearch={true}
                                options={dropDown?.['weight']?.option}
                                onUpdate={(e) => handleSelect(e,'basicWeight')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.basicWeight?.value ? [myProfileObject.basicWeight.value] : []}
                                selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative border border-[#ced4da] rounded-[10px]'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="weight"
                            />
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Body</h5>
                            <DropDown
                                options={dropDown?.['body']?.option}
                                onUpdate={(e) => handleSelect(e,'basicBodyType')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.basicBodyType?.value ? [myProfileObject.basicBodyType.value] : []}
                                selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative border border-[#ced4da] rounded-[10px]'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="body"
                            />
                        </div>
                        <div className="px-[15px] md:col-span-2">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Marital Status *</h5>
                            <div className="flex justify-between items-center my-[10px] flex-wrap">
                                {dropDown?.['maritalStatus']?.option.map((option, index) => (
                                    <div key={index}>
                                        <RadioButton
                                            inputId={`${option.key}+maritalStatus`}
                                            modelValue={`${option.key}+maritalStatus`}
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            name="maritalStatus"
                                            label={option.name}
                                            checked={`${myProfileObject?.basicMaritalStatus?.value || ''}+maritalStatus` === `${option.key}+maritalStatus` || false}
                                            onClick={(value) => handleSelect(value, 'basicMaritalStatus')}
                                            class={'text-[18px] text-[#262626] font-family-Quicksand-Medium text-stroke leading-[30px] mr-[10px] sm:mr-0'}
                                            inputClass={'w-[18px] h-[18px] mr-[5px]'}
                                        />
                                    </div>
                                ))}
                                {myProfileObject?.basicMaritalStatus?.error &&
                                    <small className="leading-[normal] mt-[-5px] mb-[5px] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{myProfileObject.basicMaritalStatus.error}</small>
                                }
                            </div>
                        </div>
                        {
                            Number(myProfileObject.basicMaritalStatus?.value) !== 1 ? 
                                <div className="px-[15px]">
                                    <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Have a Children</h5>
                                    <div className="flex justify-between items-center my-[10px] w-[63%]">
                                        {dropDown?.['yesNo']?.option.map((option, index) => (
                                            <div key={index}>
                                                <RadioButton
                                                    inputId={`${option.key}+children`}
                                                    modelValue={`${option.key}+children`}
                                                    isDisabled={false}
                                                    isReadonly={false}
                                                    isDirectFocus={false}
                                                    isRequired={false}
                                                    isOutline={true}
                                                    name="children"
                                                    onClick={(value) => handleSelect(value, 'basicHaveAChildren')}
                                                    label={option.name}
                                                    checked={`${myProfileObject?.basicHaveAChildren?.value}+children`  === `${option.key}+children` || false}
                                                    class={'text-[18px] text-[#931217] font-family-Quicksand-Medium text-stroke'}
                                                    inputClass={'w-[18px] h-[18px] mr-[5px]'}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            : null
                        }
                        {
                            myProfileObject?.basicHaveAChildren?.value && myProfileObject?.basicMaritalStatus?.value && Number(myProfileObject?.basicHaveAChildren?.value) === 1 && Number(myProfileObject?.basicMaritalStatus?.value) !== 1 ? 
                                <div className="px-[15px]">
                                    <CustomInput
                                        inputId="numberofchildren"
                                        placeHolder={'Enter no. of childrens'}
                                        label="No. of Childrens"
                                        inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular px-[16px] py-[5px] h-[41px] mb-[12px] mt-[5px] border border-[#ced4da] rounded-[10px]'}
                                        class={'leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px] block'}
                                        type="number"
                                        modelValue={myProfileObject?.basicNumberOfChildrens?.value ? myProfileObject?.basicNumberOfChildrens?.value : ''}
                                        isDisabled={false}
                                        isReadonly={false}
                                        isDirectFocus={false}
                                        isRequired={false}
                                        isOutline={true}
                                        onChange={(value,event) => handleSelect(event,'numberOfChildrens')}
                                        onKeyPress={(event)=>{
                                            handleKeyPress(event, 'remark')
                                            if(event.event.charCode === 45 || event.event.charCode === 43 || event.event.charCode === 46 || event.event.key === 'e' || event.event.key === 'E'){
                                                event.event.preventDefault();
                                            }
                                        }}
                                        onKeyDown={(event)=>{
                                            if(event.event.key === 'ArrowUp' || event.event.key === 'ArrowDown'){
                                                event.event.preventDefault();
                                            }
                                        }}
                                        onPaste={(event) => {
                                            event.event.preventDefault();
                                        }}
                                    />
                                </div>
                            : null
                        }
                        {
                            myProfileObject?.basicHaveAChildren?.value && myProfileObject?.basicMaritalStatus?.value && Number(myProfileObject?.basicHaveAChildren?.value) === 1 && Number(myProfileObject?.basicMaritalStatus?.value) !== 1 ? 
                                <div className="px-[15px] md:col-span-2">
                                    <CustomInput
                                        inputId="remark"
                                        label={`${dropDown?.['maritalStatus']?.option.find(e => e.key === Number(myProfileObject?.basicMaritalStatus?.value)).name} Remark`}
                                        inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[41px] mb-[12px] mt-[5px] border border-[#ced4da] rounded-[10px]'}
                                        class={'leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px] block'}
                                        type="text"
                                        modelValue={
                                            Number(myProfileObject?.basicMaritalStatus?.value) === 2 ? myProfileObject?.basicWidowedRemark?.value ? myProfileObject?.basicWidowedRemark?.value : '' : 
                                            Number(myProfileObject?.basicMaritalStatus?.value) === 3 ? myProfileObject?.basicDivorcedRemark?.value ? myProfileObject?.basicDivorcedRemark?.value : '' :  
                                            Number(myProfileObject?.basicMaritalStatus?.value) === 4 ? myProfileObject?.basicAwaitingDivorcedRemark?.value ? myProfileObject?.basicAwaitingDivorcedRemark?.value : '' :
                                            ''
                                        }
                                        placeHolder={'Enter remark'}
                                        isDisabled={false}
                                        isReadonly={false}
                                        isDirectFocus={false}
                                        isRequired={false}
                                        isOutline={true}
                                        onChange={(_,event) => handleSelect(event,`${myProfileObject?.basicMaritalStatus?.value}`)}
                                        onKeyPress={(event)=>{handleKeyPress(event, 'religion')}}
                                    />
                                </div>
                            : null
                        }
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Religion *</h5>
                            <DropDown
                                options={dropDown?.['religion']?.option}
                                onUpdate={(e) => handleSelect(e,'basicReligion')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.basicReligion?.value ? [myProfileObject.basicReligion?.value] : []}
                                selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative border border-[#ced4da] rounded-[10px]'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="religion"
                            />
                            {myProfileObject?.basicReligion?.error &&
                                <small className="leading-[normal] mt-[-5px] mb-[5px] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{myProfileObject.basicReligion.error}</small>
                            }
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Caste *</h5>
                            <DropDown
                                isInputSearch={true}
                                options={dropDown?.['caste']?.option}
                                onUpdate={(e) => handleSelect(e,'basicCaste')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.basicCaste?.value ? [myProfileObject.basicCaste?.value] : []}
                                selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative border border-[#ced4da] rounded-[10px]'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="caste"
                            />
                            {myProfileObject?.basicCaste?.error &&
                                <small className="leading-[normal] mt-[-5px] mb-[5px] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{myProfileObject.basicCaste.error}</small>
                            }
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Mother Tongue *</h5>
                            <DropDown
                                isInputSearch={true}
                                options={dropDown?.['motherTongue']?.option}
                                onUpdate={(e) => handleSelect(e,'basicMotherTongue')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.basicMotherTongue?.value ? [myProfileObject.basicMotherTongue?.value] : []}
                                selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative border border-[#ced4da] rounded-[10px]'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="mothertongue"
                            />
                            {myProfileObject?.basicMotherTongue?.error &&
                                <small className="leading-[normal] mt-[-5px] mb-[5px] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{myProfileObject.basicMotherTongue.error}</small>
                            }
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Disability *</h5>
                            <div className="grid md:grid-cols-[33%_67%] grid-cols-1 items-center my-[10px] md:w-[auto]">
                                {dropDown?.['disability']?.option.map((option, index) => (
                                    <div key={index}>
                                        <RadioButton
                                            inputId={`${option.key}+disability`}
                                            modelValue={`${option.key}+disability`}
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            name="disability"
                                            onClick={(value) => handleSelect(value, 'basicDisability')}
                                            label={option.name}
                                            checked={`${myProfileObject?.basicDisability?.value}+disability` === `${option.key}+disability` || false}
                                            class={'text-[18px] text-[#931217] font-family-Quicksand-Medium text-stroke'}
                                            inputClass={'w-[18px] h-[18px] mr-[5px]'}
                                        />
                                    </div>
                                ))}
                                {myProfileObject?.basicDisability?.error &&
                                    <small className="leading-[normal] mt-[-5px] mb-[5px] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{myProfileObject.basicDisability.error}</small>
                                }
                            </div>
                        </div>
                        {
                            myProfileObject?.basicDisability?.value == 2 ? 
                                <div className="px-[15px]">
                                    <CustomInput
                                        inputId="lastname"
                                        placeHolder='Enter details of disability'
                                        label="Details of Disability"
                                        inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[41px] mb-[12px] mt-[5px] border border-[#ced4da] rounded-[10px]'}
                                        class={'leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px] block'}
                                        type="text"
                                        modelValue={myProfileObject?.basicDisabilityDetail?.value || ''}
                                        isDisabled={false}
                                        isReadonly={false}
                                        isDirectFocus={false}
                                        isRequired={false}
                                        isOutline={true}
                                        onChange={(_, e) => handleSelect(e,'basicDisabilityDetail')}
                                        onKeyPress={(event) => handleKeyPress(event, 'profile')}
                                    />
                                </div>
                            : null
                        }
                        <div className="px-[15px] mt-[22px] md:col-span-2">
                            <CheckBox
                                modelValue={myProfileObject?.basicWillingToMarry?.value}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                onClick={(value) => handleSelect(value,'willingToMarray')}
                                label={'Willing to marry from other communities also'}
                                labelClass={'leading-[normal] cursor-pointer font-family-Quicksand-Bold text-[#931217] text-[18px] ml-[8px]'}
                                checked={myProfileObject?.basicWillingToMarry?.value || false}
                                isCheckBy={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="bg-[#f0e1b9] rounded-[12px] pt-[20px] pb-[10px] mb-[30px]">
                    <h4 className="px-[25px] text-[#ed1c24] ms:leading-[1.2] leading-[normal] font-family-ZCOOLXiaoWei-Regular ms:text-[30px] text-[24px]">About Me *</h4>
                    <div className="ms:pt-[20px] ms:pb-[14px] ms:px-[12px] pt-[20px] pb-[10px] px-[0px]">
                        <div className="px-[15px]">
                        <TextArea
                            class='resize-none font-family-Quicksand-Medium text-[18px] text-stroke-2 h-[230px] w-full p-[15px] rounded-[12px]'
                            modelValue={myProfileObject?.aboutMe?.value}
                            onKeyUp={(e) => handleSelect(e,'aboutMe')}
                            inputId={"aboutme"}
                        />
                        {myProfileObject?.aboutMe?.error &&
                            <small className="leading-[normal] mt-[-5px] mb-[5px] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{myProfileObject.aboutMe.error}</small>
                        }
                        </div>
                    </div>
                </div>
                <div className="bg-[#f0e1b9] rounded-[12px] pt-[20px] pb-[10px] mb-[30px]">
                    <h4 className="px-[25px] text-[#ed1c24] ms:leading-[1.2] leading-[normal] font-family-ZCOOLXiaoWei-Regular ms:text-[30px] text-[24px]">Location Detail</h4>
                    <div className="grid grid-cols-1 md:grid-cols-2 ms:pt-[20px] ms:pb-[10px] ms:px-[12px] pt-[20px] pb-[10px] px-[0px]">
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">City, State, Country *</h5>
                            <Autocomplete
                                apiKey={env.GOOGLEAPIKEY}
                                onPlaceSelected={(place) => {handleSelect(place,'location')}}
                                types={['(regions)']}
                                fields={['address_components']}
                                className='w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[41px] mb-[12px] mt-[5px] border border-[#ced4da] rounded-[10px]'
                                placeholder={'Enter city state country'}
                                defaultValue={myProfileObject?.locationCity?.value && myProfileObject?.locationState?.value && myProfileObject?.locationCountry?.value ? `${myProfileObject?.locationCity?.value}, ${myProfileObject?.locationState?.value}, ${myProfileObject?.locationCountry?.value}` : ''}
                                onChange={(e) => handleChange(e)}
                                onKeyPress={(e) => {
                                    let event = {
                                        event:e
                                    }
                                    handleKeyPress(event,'resident')
                                }}
                                id="citycountrystate"
                            />
                            { myProfileObject?.locationCountry?.error || myProfileObject?.locationCity?.error || myProfileObject?.locationState?.error ?
                                <small className="leading-[normal] mt-[-5px] mb-[5px] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">The city state country field is required</small>
                                :myProfileObject?.locationCountry?.error ? <small className="leading-[normal] mt-[-5px] mb-[5px] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">please select the valid address</small> 
                                :myProfileObject?.locationCity?.error ? <small className="leading-[normal] mt-[-5px] mb-[5px] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">please select the valid address</small>
                                :myProfileObject?.locationState?.error ? <small className="leading-[normal] mt-[-5px] mb-[5px] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">please select the valid address</small>
                                :null
                            }
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Resident/Visa Status</h5>
                            <DropDown
                                isInputSearch={true}
                                options={dropDown?.['resident']?.option}
                                onUpdate={(value) => handleSelect(value, 'locationResident')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.locationResident?.value ? [myProfileObject.locationResident.value] : []}
                                selectClass={'border border-[#ced4da] rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="resident"
                            />
                        </div>
                    </div>
                </div>
                <div className="bg-[#f0e1b9] rounded-[12px] pt-[20px] pb-[10px] mb-[30px]">
                    <h4 className="px-[25px] text-[#ed1c24] ms:leading-[1.2] leading-[normal] font-family-ZCOOLXiaoWei-Regular ms:text-[30px] text-[24px]">Horoscope Details</h4>
                    <div className="grid grid-cols-1 md:grid-cols-2 ms:pt-[20px] ms:pb-[10px] ms:px-[12px] pt-[20px] pb-[10px] px-[0px]">
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Star</h5>
                            <DropDown
                                isInputSearch={true}
                                options={dropDown?.['star']?.option}
                                onUpdate={(value) => handleSelect(value, 'horoscopeStar')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.horoscopeStar?.value ? [myProfileObject.horoscopeStar?.value] : []}
                                selectClass={'border border-[#ced4da] rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="star"
                            />
                        </div>
                        <div className="px-[15px] date_picker_wrapper">
                            <label className={'leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px] block'}>Date of Birth *</label>
                            <Flatpickr
                                id="brithdate"
                                options={{
                                    maxDate: userDetails?.gender === 2 ? moment(moment().subtract(18, 'years').toDate()).format('YYYY-MM-DD') : moment(moment().subtract(21, 'years').toDate()).format('YYYY-MM-DD'),
                                    enableTime: false,
                                    dateFormat: 'd-m-Y', // Format to display date and time
                                    static: true
                                }}
                                value={myProfileObject?.horoscopeDateOfBirth?.value ? moment(myProfileObject?.horoscopeDateOfBirth.value).format('DD-MM-YYYY') : ''}
                                placeholder="DD-MM-YYYY"
                                onChange={date => handleSelect(date,'horoscopeDateOfBirth')}
                                className='custom-date-container w-full bg-[transparent] px-[16px] py-[5px] h-[41px] mb-[12px] z-[1] cursor-pointer'
                            />
                            {myProfileObject?.horoscopeDateOfBirth?.error &&
                                <small className="leading-[normal] mt-[-5px] mb-[5px] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{myProfileObject.horoscopeDateOfBirth.error}</small>
                            }
                        </div>
                        <div className="px-[15px] date_picker_wrapper">
                            <label className='leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px] block'>Time of Birth</label>
                            <TimePicker
                                ref={timePickerRef3}
                                id="timeofbrith"
                                showSecond={false}
                                defaultValue={getInitialTimes(myProfileObject?.horoscopeTimeOfBirth?.value)}
                                onChange={(value) => handleSelect(value,'horoscopeTimeOfBirth')}
                                format="HH:mm"
                                placeholder="HH:mm"
                                use12Hours={false}
                                className="w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] h-[41px] mb-[12px] mt-[5px] border border-[#ced4da] rounded-[10px]"
                            />
                        </div>
                        <div className="px-[15px]">
                            <CustomInput
                                inputId="stateofbirth"
                                placeHolder="Enter State of Birth"
                                label="State of Birth"
                                inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[41px] mb-[12px] mt-[5px] border border-[#ced4da] rounded-[10px]'}
                                class={'leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px] block'}
                                type="text"
                                modelValue={myProfileObject?.horoscopeStateOfBirth?.value}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                onChange={(_, e) => handleSelect(e,'horoscopeStateOfBirth')}
                                onKeyPress={(event)=>{handleKeyPress(event, 'cityofbirth')}}
                            />
                        </div>
                        <div className="px-[15px]">
                            <CustomInput
                                inputId="cityofbirth"
                                placeHolder="Enter City of Birth"
                                label="City of Birth"
                                inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[41px] mb-[12px] mt-[5px] border border-[#ced4da] rounded-[10px]'}
                                class={'leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px] block'}
                                type="text"
                                modelValue={myProfileObject?.horoscopeCityOfBirth?.value}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                onChange={(_, e) => handleSelect(e,'horoscopeCityOfBirth')}
                                onKeyPress={(event)=>{handleKeyPress(event, 'gor')}}
                            />
                        </div>
                        <div className="px-[15px]">
                            <CustomInput
                                inputId="gor"
                                placeHolder="Enter Gor"
                                label="Gor *"
                                inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[41px] mb-[12px] mt-[5px] border border-[#ced4da] rounded-[10px]'}
                                class={'leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px] block'}
                                type="text"
                                modelValue={myProfileObject?.basicGor?.value ? myProfileObject?.basicGor?.value : ''}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                onKeyPress={(event)=>{handleKeyPress(event, 'highesteducation')}}
                                onChange={(_, e) => handleSelect(e,'gor')}
                            />
                            {myProfileObject?.basicGor?.error &&
                                <small className="leading-[normal] mt-[-5px] mb-[5px] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{myProfileObject.basicGor.error}</small>
                            }
                        </div>
                        <div className="px-[15px] md:col-span-2">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Manglik *</h5>
                            <div className="flex justify-between items-center mt-[15px] mb-[10px] flex-wrap">
                                {dropDown?.['manglik']?.option.map((option, index) => (
                                    <div key={index}>
                                        <RadioButton
                                            inputId={`${option.key}+manglik`}
                                            modelValue={`${option.key}+manglik`}
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            name="manglik"
                                            onClick={(value) => handleSelect(value, 'horoscopeManglik')}
                                            label={option.name}
                                            checked={`${myProfileObject?.horoscopeManglik?.value}+manglik` === `${option.key}+manglik` || false}
                                            class={'text-[18px] text-[#262626] font-family-Quicksand-Medium text-stroke leading-[30px] mr-[10px] sm:mr-0'}
                                            inputClass={'w-[18px] h-[18px] mr-[5px]'}
                                        />
                                    </div>
                                ))}
                                {myProfileObject?.horoscopeManglik?.error &&
                                    <small className="leading-[normal] mt-[-5px] mb-[5px] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{myProfileObject.horoscopeManglik.error}</small>
                                }
                            </div>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Horoscope Match is Neccessary?</h5>
                            <div className="flex justify-between items-center mt-[15px] mb-[10px] flex-wrap w-1/2">
                                {dropDown?.['yesNo']?.option.map((option, index) => (
                                    <div key={index}>
                                        <RadioButton
                                            inputId={`${option.key}+horoscopeMatch`}
                                            modelValue={`${option.key}+horoscopeMatch`}
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            name="horoscopeMatch"
                                            onClick={(value) => handleSelect(value, 'horoscopeMatch')}
                                            label={option.name}
                                            checked={`${myProfileObject?.horoscopeMatch?.value}+horoscopeMatch` === `${option.key}+horoscopeMatch` || false}
                                            class={'text-[18px] text-[#931217] font-family-Quicksand-Medium text-stroke'}
                                            inputClass={'w-[18px] h-[18px] mr-[5px]'}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Believe in Horoscope?</h5>
                            <div className="flex justify-between items-center mt-[15px] mb-[10px] flex-wrap w-1/2">
                                {dropDown?.['yesNo']?.option?.map((option, index) => (
                                    <div key={index}>
                                        <RadioButton
                                            inputId={`${option.key}+believeInHoroscope`}
                                            modelValue={`${option.key}+believeInHoroscope`}
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            name="believeInHoroscope"
                                            onClick={(value) => handleSelect(value, 'horoscopeBelieveInHoroscope')}
                                            label={option.name}
                                            checked={`${myProfileObject?.horoscopeBelieveInHoroscope?.value}+believeInHoroscope` === `${option.key}+believeInHoroscope` || false}
                                            class={'text-[18px] text-[#931217] font-family-Quicksand-Medium text-stroke'}
                                            inputClass={'w-[18px] h-[18px] mr-[5px]'}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-[#f0e1b9] rounded-[12px] pt-[20px] pb-[10px] mb-[30px]">
                    <h4 className="px-[25px] text-[#ed1c24] ms:leading-[1.2] leading-[normal] font-family-ZCOOLXiaoWei-Regular ms:text-[30px] text-[24px]">Education & Career</h4>
                    <div className="grid grid-cols-1 md:grid-cols-2 ms:pt-[20px] ms:pb-[10px] ms:px-[12px] pt-[20px] pb-[10px] px-[0px]">
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Highest Qualification *</h5>
                            <DropDown
                                isInputSearch={true}
                                options={dropDown?.['degree']?.option}
                                onUpdate={(value) => handleSelect(value, 'educationHighestQualification')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.educationHighestQualification?.value ? [myProfileObject.educationHighestQualification?.value] : []}
                                selectClass={'border border-[#ced4da] rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="highesteducation"
                            />
                            {myProfileObject?.educationHighestQualification?.error &&
                                <small className="leading-[normal] mt-[-5px] mb-[5px] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{myProfileObject.educationHighestQualification.error}</small>
                            }
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Additional Degree</h5>
                            <DropDown
                                isInputSearch={true}
                                options={dropDown?.['degree']?.option}
                                onUpdate={(value) => handleSelect(value, 'educationAdditionalDegree')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.educationAdditionalDegree?.value ? [myProfileObject?.educationAdditionalDegree?.value] : []}
                                selectClass={'border border-[#ced4da] rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="additionaleducation"
                            />
                        </div>
                        <div className="px-[15px]">
                            <CustomInput
                                placeHolder={'Enter Education Subject'}
                                label="Education Subject"
                                inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[41px] mb-[12px] mt-[5px] border border-[#ced4da] rounded-[10px]'}
                                class={'leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px] block'}
                                type="text"
                                modelValue={myProfileObject?.educationSubject?.value}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                onChange={(_, e) => handleSelect(e,'educationSubject')}
                                inputId="educationsubject"
                                onKeyPress={(event)=>{handleKeyPress(event, 'educationindetail')}}
                            />
                        </div>
                        <div className="px-[15px]">
                            <CustomInput
                                placeHolder={'Enter Education in Detail'}
                                label="Education in Detail"
                                inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[41px] mb-[12px] mt-[5px] border border-[#ced4da] rounded-[10px]'}
                                class={'leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px] block'}
                                type="text"
                                modelValue={myProfileObject?.educationInDetail?.value}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                onChange={(_, e) => handleSelect(e,'educationInDetail')}
                                inputId="educationindetail"
                                onKeyPress={(event)=>{handleKeyPress(event, 'educationcollege')}}
                            />
                        </div>
                        <div className="px-[15px]">
                            <CustomInput
                                label="College / Institute"
                                inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[41px] mb-[12px] mt-[5px] border border-[#ced4da] rounded-[10px]'}
                                placeHolder={'Enter College / Institute'}
                                class={'leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px] block'}
                                type="text"
                                modelValue={myProfileObject?.educationCollege?.value}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                onChange={(_, e) => handleSelect(e,'educationCollege')}
                                inputId="educationcollege"
                                onKeyPress={(event)=>{handleKeyPress(event, 'employedIn')}}
                            />
                        </div>
                        
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Employed In</h5>
                            <DropDown
                                isInputSearch={true}
                                options={dropDown?.['employedIn']?.option}
                                onUpdate={(value) => handleSelect(value, 'educationEmployeedIn')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.educationEmployeedIn?.value ? [myProfileObject.educationEmployeedIn?.value] : []}
                                selectClass={'border border-[#ced4da] rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="employedIn"
                            />
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Occupation *</h5>
                            <DropDown
                                isInputSearch={true}
                                options={dropDown?.['occupation']?.option}
                                onUpdate={(value) => handleSelect(value, 'educationOccupation')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.educationOccupation?.value ? [myProfileObject.educationOccupation?.value] : []}
                                selectClass={'border border-[#ced4da] rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="educationoccupation"
                            />
                            {myProfileObject?.educationOccupation?.error &&
                                <small className="leading-[normal] mt-[-5px] mb-[5px] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{myProfileObject.educationOccupation.error}</small>
                            }
                        </div>
                        <div className="px-[15px]">
                            <CustomInput
                                label="Occupation in Detail"
                                inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[41px] mb-[12px] mt-[5px] border border-[#ced4da] rounded-[10px]'}
                                placeHolder={'Enter Occupation in Detail'}
                                class={'leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px] block'}
                                type="text"
                                modelValue={myProfileObject?.educationOccupationDetail?.value}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                onChange={(_, e) => handleSelect(e,'educationOccupationDetail')}
                                inputId="educationOccupationDetail"
                                onKeyPress={(event)=>{handleKeyPress(event, 'educationOrganization')}}
                            />
                        </div>
                        <div className="px-[15px]">
                            <CustomInput
                                label="Organization"
                                inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[41px] mb-[12px] mt-[5px] border border-[#ced4da] rounded-[10px]'}
                                placeHolder={'Enter Organization'}
                                class={'leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px] block'}
                                type="text"
                                modelValue={myProfileObject?.educationOrganization?.value}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                onChange={(_, e) => handleSelect(e,'educationOrganization')}
                                inputId="educationOrganization"
                                onKeyPress={(event)=>{handleKeyPress(event, 'educationAnnualIncome')}}
                            />
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Annual Income</h5>
                            <DropDown
                                isInputSearch={true}
                                options={dropDown?.['annualIncome']?.option}
                                onUpdate={(value) => handleSelect(value, 'educationAnnualIncome')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.educationAnnualIncome?.value ? [myProfileObject.educationAnnualIncome?.value] : []}
                                selectClass={'border border-[#ced4da] rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="educationAnnualIncome"
                            />
                        </div>
                    </div>
                </div>
                <div className="bg-[#f0e1b9] rounded-[12px] pt-[20px] pb-[10px] mb-[30px]">
                    <h4 className="px-[25px] text-[#ed1c24] ms:leading-[1.2] leading-[normal] font-family-ZCOOLXiaoWei-Regular ms:text-[30px] text-[24px]">About Family</h4>
                    <div className="grid grid-cols-1 md:grid-cols-2 ms:pt-[20px] ms:pb-[10px] ms:px-[12px] pt-[20px] pb-[10px] px-[0px]">
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Father's Occupation</h5>
                            <DropDown
                                options={dropDown?.['fatherOccupation']?.option}
                                onUpdate={(value) => handleSelect(value, 'familyFatherOccupation')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.familyFatherOccupation?.value ? [myProfileObject.familyFatherOccupation?.value] : []}
                                selectClass={'border border-[#ced4da] rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="familyfatheroccupation"
                            />
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Mother's Occupation</h5>
                            <DropDown
                                options={dropDown?.['motherOccupation']?.option}
                                onUpdate={(value) => handleSelect(value, 'familyMotherOccupation')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.familyMotherOccupation?.value ? [myProfileObject.familyMotherOccupation?.value] : []}
                                selectClass={'border border-[#ced4da] rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="familymotheroccupation"
                            />
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Sister(s)</h5>
                            <div className='flex justify-between xms:flex-row flex-col'>
                                <DropDown
                                    options={dropDown?.['sisterBrother']?.option}
                                    onUpdate={(value) => handleSelect(value, 'familySister')}
                                    multiSelect={false}
                                    selectedValue={myProfileObject?.familySister?.value ? [myProfileObject.familySister?.value] : []}
                                    selectClass={'border border-[#ced4da] rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                    wrapperClass={'mb-[12px] mt-[5px] relative xms:w-[40.1%] w-full'}
                                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                    optionsWrapper={'bg-white'}
                                    focusInd="sister"
                                />
                                <DropDown
                                    options={dropDown?.['sisterBrotherMarried']?.option.filter(e => e.key <= myProfileObject?.familySister?.value)?.length ? dropDown?.['sisterBrotherMarried']?.option.filter(e => e.key <= myProfileObject.familySister?.value) : dropDown?.['sisterBrotherMarried']?.option.filter(e => e.key <= 0)}
                                    onUpdate={(value) => handleSelect(value, 'familyMarriedSister')}
                                    multiSelect={false}
                                    selectedValue={myProfileObject?.familyMarriedSister?.value ? [myProfileObject.familyMarriedSister?.value] : []}
                                    selectClass={'border border-[#ced4da] rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                    wrapperClass={'mb-[12px] mt-[5px] relative xms:w-[55.5%] w-full'}
                                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                    optionsWrapper={'bg-white'}
                                    focusInd="sistermarried"
                                />
                            </div>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Brother(s)</h5>
                            <div className="flex justify-between xms:flex-row flex-col">
                                <DropDown
                                    options={dropDown?.['sisterBrother']?.option}
                                    onUpdate={(value) => handleSelect(value, 'familyBrother')}
                                    multiSelect={false}
                                    selectedValue={myProfileObject?.familyBrother?.value ? [myProfileObject.familyBrother?.value] : []}
                                    selectClass={'border border-[#ced4da] rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                    wrapperClass={'mb-[12px] mt-[5px] relative xms:w-[40.1%] w-full'}
                                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                    optionsWrapper={'bg-white'}
                                    focusInd="brother"
                                />
                                <DropDown
                                    options={dropDown?.['sisterBrotherMarried']?.option.filter(e => e.key <= myProfileObject?.familyBrother?.value)?.length ? dropDown?.['sisterBrotherMarried']?.option.filter(e => e.key <= myProfileObject?.familyBrother?.value) : dropDown?.['sisterBrotherMarried']?.option.filter(e => e.key <= 0)}
                                    onUpdate={(value) => handleSelect(value, 'familyMarriedBrother')}
                                    multiSelect={false}
                                    selectedValue={myProfileObject?.familyMarriedBrother?.value ? [myProfileObject.familyMarriedBrother?.value] : []}
                                    selectClass={'border border-[#ced4da] rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                    wrapperClass={'mb-[12px] mt-[5px] relative xms:w-[55.5%] w-full'}
                                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                    optionsWrapper={'bg-white'}
                                    focusInd="brothermarried"
                                />
                            </div>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Family Status *</h5>
                            <DropDown
                                options={dropDown?.['familyStatus']?.option}
                                onUpdate={(value) => handleSelect(value, 'familyStatus')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.familyStatus?.value ? [myProfileObject.familyStatus?.value] : []}
                                selectClass={'border border-[#ced4da] rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="familystatus"
                            />
                            {myProfileObject?.familyStatus?.error &&
                                <small className="leading-[normal] mt-[-5px] mb-[5px] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{myProfileObject.familyStatus.error}</small>
                            }
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Family Type</h5>
                            <DropDown
                                options={dropDown?.['familyType']?.option}
                                onUpdate={(value) => handleSelect(value, 'familyType')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.familyType?.value ? [myProfileObject.familyType?.value] : []}
                                selectClass={'border border-[#ced4da] rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="familytype"
                            />
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Family Values *</h5>
                            <DropDown
                                options={dropDown?.['familyValues']?.option}
                                onUpdate={(value) => handleSelect(value, 'familyValues')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.familyValues?.value ? [myProfileObject.familyValues?.value] : []}
                                selectClass={'border border-[#ced4da] rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="familyvalues"
                            />
                            {myProfileObject?.familyValues?.error &&
                                <small className="leading-[normal] mt-[-5px] mb-[5px] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{myProfileObject.familyValues.error}</small>
                            }
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Family Income</h5>
                            <DropDown
                                isInputSearch={true}
                                options={dropDown?.['annualIncome']?.option}
                                onUpdate={(value) => handleSelect(value, 'familyIncome')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.familyIncome?.value ? [myProfileObject.familyIncome?.value] : []}
                                selectClass={'border border-[#ced4da] rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="annualincome"
                            />
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Family Live In</h5>
                            <Autocomplete
                                id="familyliving"
                                apiKey={env.GOOGLEAPIKEY}
                                onPlaceSelected={(place) => {handleSelect(place,'familyLiving')}}
                                types={['(regions)']}
                                fields={['address_components']}
                                className='w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[41px] mb-[12px] mt-[5px] border border-[#ced4da] rounded-[10px]'
                                placeholder={'Enter city state country'}
                                defaultValue={myProfileObject?.familyLivingIn?.value && myProfileObject?.familyLivingState?.value && myProfileObject?.familyLivingCountry?.value ? `${myProfileObject?.familyLivingIn?.value}, ${myProfileObject?.familyLivingState?.value}, ${myProfileObject?.familyLivingCountry?.value}` : ''}
                                onChange={(e) => handleChangeLivingWith(e)}
                                onKeyPress={(e) => {
                                    let event = {
                                        event:e
                                    }
                                    handleKeyPress(event, 'eatinghabits')
                                }}
                            />
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">You Live With Your Family</h5>
                            <div className="flex justify-between items-center my-[10px]  w-1/2">
                                {dropDown?.['yesNo']?.option.map((option, index) => (
                                    <div key={index}>
                                        <RadioButton
                                            inputId={`${option.key}+liveWithFamily`}
                                            modelValue={`${option.key}+liveWithFamily`}
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            name="liveWithFamily"
                                            onClick={(value) => handleSelect(value, 'familyLiveWithFamily')}
                                            label={option.name}
                                            checked={`${myProfileObject?.familyLiveWithFamily?.value}+liveWithFamily` === `${option.key}+liveWithFamily` || false}
                                            class={'text-[18px] text-[#931217] font-family-Quicksand-Medium text-stroke'}
                                            inputClass={'w-[18px] h-[18px] mr-[5px]'}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-[#f0e1b9] rounded-[12px] pt-[20px] pb-[10px] mb-[30px]">
                    <h4 className="px-[25px] text-[#ed1c24] ms:leading-[1.2] leading-[normal] font-family-ZCOOLXiaoWei-Regular ms:text-[30px] text-[24px]">Lifestyle</h4>
                    <div className="grid grid-cols-1 md:grid-cols-2 ms:pt-[20px] ms:pb-[10px] ms:px-[12px] pt-[20px] pb-[10px] px-[0px]">
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Eating Habits</h5>
                            <DropDown
                                options={dropDown?.['eatingHabits']?.option}
                                onUpdate={(value) => handleSelect(value, 'lifeStyleEatingHabits')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.lifeStyleEatingHabits?.value ? [myProfileObject.lifeStyleEatingHabits?.value] : []}
                                selectClass={'border border-[#ced4da] rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="eatinghabits"
                            />
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Smoking Habits</h5>
                            <DropDown
                                options={dropDown?.['smokingHabits']?.option}
                                onUpdate={(value) => handleSelect(value, 'lifeStyleSmokingHabits')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.lifeStyleSmokingHabits?.value ? [myProfileObject.lifeStyleSmokingHabits?.value] : []}
                                selectClass={'border border-[#ced4da] rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="smokinghabits"
                            />
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Drinking Habits</h5>
                            <DropDown
                                options={dropDown?.['drinkingHabits']?.option}
                                onUpdate={(value) => handleSelect(value, 'lifeStyleDrinkingHabits')}
                                multiSelect={false}
                                selectedValue={myProfileObject?.lifeStyleDrinkingHabits?.value ? [myProfileObject.lifeStyleDrinkingHabits?.value] : []}
                                selectClass={'border border-[#ced4da] rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[4.5px] h-[41px]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="drinkinghabits"
                            />
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Own a House?</h5>
                            <div className="flex justify-between items-center my-[10px] w-1/2">
                                {dropDown?.['yesNo']?.option.map((option, index) => (
                                    <div key={index}>
                                        <RadioButton
                                            inputId={`${option.key}+ownHouse`}
                                            modelValue={`${option.key}+ownHouse`}
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            name="ownHouse"
                                            onClick={(value) => handleSelect(value, 'lifeStyleOwnsHouse')}
                                            label={option.name}
                                            checked={`${myProfileObject?.lifeStyleOwnsHouse?.value}+ownHouse` === `${option.key}+ownHouse` || false}
                                            class={'text-[18px] text-[#931217] font-family-Quicksand-Medium text-stroke cursor-pointer'}
                                            inputClass={'w-[18px] h-[18px] mr-[5px]'}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Own a Car?</h5>
                            <div className="flex justify-between items-center my-[10px] w-1/2">
                                {dropDown?.['yesNo']?.option.map((option, index) => (
                                    <div key={index}>
                                        <RadioButton
                                            inputId={`${option.key}+ownCar`}
                                            modelValue={`${option.key}+ownCar`}
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            name="ownCar"
                                            onClick={(value) => handleSelect(value, 'lifeStyleOwnsCar')}
                                            label={option.name}
                                            checked={`${myProfileObject?.lifeStyleOwnsCar?.value}+ownCar` === `${option.key}+ownCar` || false}
                                            class={'text-[18px] text-[#931217] font-family-Quicksand-Medium text-stroke cursor-pointer'}
                                            inputClass={'w-[18px] h-[18px] mr-[5px]'}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Languages known</h5>
                            <DropDown
                                isInputSearch={true}
                                options={dropDown?.['languagesknown']?.option}
                                onUpdate={(value) => handleSelect(value, 'lifeStyleLanguages')}
                                multiSelect={true}
                                selectedValue={myProfileObject?.lifeStyleLanguages?.value ? myProfileObject.lifeStyleLanguages?.value : []}
                                selectClass={'max-h-[300px] overflow-auto border border-[#ced4da] flex-wrap rounded-[10px] after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular px-[16px] py-[5.3px] h-[auto]'}
                                wrapperClass={'mb-[12px] mt-[5px] relative'}
                                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                optionsWrapper={'bg-white'}
                                focusInd="languagesknown"
                            />
                        </div>
                    </div>
                </div>
                <div className="bg-[#f0e1b9] rounded-[12px] pt-[20px] pb-[10px] mb-[30px]">
                    <h4 className="px-[25px] text-[#ed1c24] ms:leading-[1.2] leading-[normal] font-family-ZCOOLXiaoWei-Regular ms:text-[30px] text-[24px]">Interest & More</h4>
                    <div className="ms:pt-[20px] ms:pb-[10px] ms:px-[12px] pt-[20px] pb-[10px] px-[0px]">
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Hobbies</h5>
                            <div className='flex items-center flex-wrap my-[10px]'>
                                {dropDown?.['hobbies']?.option.map((element, index) => (
                                    <span onClick={() => handleSelect(element,'interestHobbies')} className={`${myProfileObject?.interestHobbies?.value.includes(element.key) ? 'bg-[#ee1c24] text-white border-[#ee1c24]]' : 'border-[#808080]'} cursor-pointer block border my-[5px] mx-[5px] px-[10px] py-[5px] rounded-[50px]`} key={index}>{element.name}</span>
                                ))}
                            </div>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Interest</h5>
                            <div className='flex items-center flex-wrap my-[10px]'>
                                {dropDown?.['interest']?.option.map((element, index) => (
                                    <span onClick={() => handleSelect(element,'interest')} className={`${myProfileObject?.interest?.value.includes(element.key) ? 'bg-[#ee1c24] text-white border-[#ee1c24]]' : 'border-[#808080]'} cursor-pointer block border my-[5px] mx-[5px] px-[10px] py-[5px] rounded-[50px]`} key={index}>{element.name}</span>
                                ))}
                            </div>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Music</h5>
                            <div className='flex items-center flex-wrap my-[10px]'>
                                {dropDown?.['music']?.option.map((element, index) => (
                                    <span onClick={() => handleSelect(element,'interestMusic')} className={`${myProfileObject?.interestMusic?.value?.includes(element.key) ? 'bg-[#ee1c24] text-white border-[#ee1c24]]' : 'border-[#808080]'} cursor-pointer block border my-[5px] mx-[5px] px-[10px] py-[5px] rounded-[50px]`} key={index}>{element.name}</span>
                                ))}
                            </div>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Favourite Read</h5>
                            <div className='flex items-center flex-wrap my-[10px]'>
                                {dropDown?.['favouriteRead']?.option.map((element, index) => (
                                    <span onClick={() => handleSelect(element,'interestFavouriteRead')} className={`${myProfileObject?.interestFavouriteRead?.value?.includes(element.key) ? 'bg-[#ee1c24] text-white border-[#ee1c24]]' : 'border-[#808080]'} cursor-pointer block border my-[5px] mx-[5px] px-[10px] py-[5px] rounded-[50px]`} key={index}>{element.name}</span>
                                ))}
                            </div>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Dress Style</h5>
                            <div className='flex items-center flex-wrap my-[10px]'>
                                {dropDown?.['dressStyle']?.option.map((element, index) => (
                                    <span onClick={() => handleSelect(element,'interestDressStyle')} className={`${myProfileObject?.interestDressStyle?.value?.includes(element.key) ? 'bg-[#ee1c24] text-white border-[#ee1c24]]' : 'border-[#808080]'} cursor-pointer block border my-[5px] mx-[5px] px-[10px] py-[5px] rounded-[50px]`} key={index}>{element.name}</span>
                                ))}
                            </div>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Favourite TV Shows</h5>
                            <div className='flex items-center flex-wrap my-[10px]'>
                                {dropDown?.['favouriteTvShows']?.option.map((element, index) => (
                                    <span onClick={() => handleSelect(element,'interestFavouriteTVShows')} className={`${myProfileObject?.interestFavouriteTVShows?.value.includes(element.key) ? 'bg-[#ee1c24] text-white border-[#ee1c24]]' : 'border-[#808080]'} cursor-pointer block border my-[5px] mx-[5px] px-[10px] py-[5px] rounded-[50px]`} key={index}>{element.name}</span>
                                ))}
                            </div>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Preferred Movies</h5>
                            <div className='flex items-center flex-wrap my-[10px]'>
                                {dropDown?.['preferredMovies']?.option.map((element, index) => (
                                    <span onClick={() => handleSelect(element,'interestPreferredMovies')} className={`${myProfileObject?.interestPreferredMovies?.value.includes(element.key) ? 'bg-[#ee1c24] text-white border-[#ee1c24]]' : 'border-[#808080]'} cursor-pointer block border my-[5px] mx-[5px] px-[10px] py-[5px] rounded-[50px]`} key={index}>{element.name}</span>
                                ))}
                            </div>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Sports Fitness</h5>
                            <div className='flex items-center flex-wrap my-[10px]'>
                                {dropDown?.['sportsFitness']?.option.map((element, index) => (
                                    <span onClick={() => handleSelect(element,'interestSportsFitness')} className={`${myProfileObject?.interestSportsFitness?.value.includes(element.key) ? 'bg-[#ee1c24] text-white border-[#ee1c24]]' : 'border-[#808080]'} cursor-pointer block border my-[5px] mx-[5px] px-[10px] py-[5px] rounded-[50px]`} key={index}>{element.name}</span>
                                ))}
                            </div>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Favourite Cuisine</h5>
                            <div className='flex items-center flex-wrap my-[10px]'>
                                {dropDown?.['favouriteCuisine']?.option.map((element, index) => (
                                    <span onClick={() => handleSelect(element,'interestFavouriteCusine')} className={`${myProfileObject?.interestFavouriteCusine?.value.includes(element.key) ? 'bg-[#ee1c24] text-white border-[#ee1c24]]' : 'border-[#808080]'} cursor-pointer block border my-[5px] mx-[5px] px-[10px] py-[5px] rounded-[50px]`} key={index}>{element.name}</span>
                                ))}
                            </div>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[18px]">Favourite Vacation Destination</h5>
                            <div className='flex items-center flex-wrap my-[10px]'>
                                {dropDown?.['favouriteVacationDestination']?.option.map((element, index) => (
                                    <span onClick={() => handleSelect(element,'interestFavouriteVacationDestination')} className={`${myProfileObject?.interestFavouriteVacationDestination?.value.includes(element.key) ? 'bg-[#ee1c24] text-white border-[#ee1c24]]' : 'border-[#808080]'} cursor-pointer block border my-[5px] mx-[5px] px-[10px] py-[5px] rounded-[50px]`} key={index}>{element.name}</span>
                                ))}
                            </div>
                        </div>
                        {totalLength > 5 &&
                            <small className="px-[15px] leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">The interest & more value should be less than 5 value</small>
                        }
                    </div>
                </div>
                <div className="flex justify-end">
                    <Button
                        class='leading-[normal] text-[#ee1c24] cursor-pointer py-[8px] px-[30px] border-[2px] border-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold'
                        buttonName={'Cancel'}
                        onClick={(event) => handleCancel(event)}
                    />
                    <Button
                        class='leading-[normal] text-[#fff] ml-[20px] cursor-pointer py-[8px] px-[30px] border-[2px] border-[#ee1c24] bg-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold'
                        buttonName={'Save'}
                        onClick={(event) => handleSubmit(event)}
                    />
                </div>
            </div>
        </div>
    )
};

export default EditMyProfile;