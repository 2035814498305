import Button from "../../atom/button";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import UserDetail from "../../atom/userDetail";
import * as env from '../../../config/env.js';
import MyMatchesFilter from "./myMatchesFilter.jsx";
import { apiRequest } from "../../../services/index.js";
import ProfilePicture from "../dashBoard/profilePicture";
import MyMatchesUserDetail from "./myMatchesUserDetail.jsx";
import Spinner from "../../atom/spinner/spinner.jsx";
import { useSearch } from "../../atom/SearchContext.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import { getPartnerAction } from "../../../stores/action/getPartnerAction.js";

function MyMatches() {
    const location = useLocation();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search);
    const [pageNumber,setPageNumber] = useState(queryParams.get('pageNumber') || 1);
    const navigate = useNavigate();
    const { searchData,setSearchData } = useSearch();    
    const userDetails = useSelector(state => state?.user?.user);
    const partnerDetail = useSelector(state => state?.getPartner?.getPartner);
    const [matchesRecord,setMatchesRecord] = useState([]);
    const [matchesRecordTwoWays,setMatchesRecordTwoWays] = useState([]);
    const [recentlyViewed,setRecentlyViewed] = useState([]);
    const [mutualMatches,setMutualMatches] = useState([]);
    const [recommendationByManager,setRecommendationByManager] = useState([]);
    const [matchesCount,setMatchesCount] = useState(null);
    const [matchesCountManager,setMatchesCountManager] = useState(null);
    const [isLoading,setIsLoading] = useState(false);
    const [filter,setFilter] = useState({});
    const [currentPageNumber,setCurrentPageNumber] = useState(1);
    const [currentPageNumberManager,setCurrentPageNumberManager] = useState(1);
    const [isMyMatchesFilter,setIsMyMatchesFilter] = useState(false);
    const [filterObj,setFilterObj] = useState({});
    const [mututalMessage,setMututalMessage] = useState('No Mutual Matches Found!')
    
    const getMyMatchesData =(skip,limit,currentPage,id) => {
        try {
            setIsLoading(true);
            setMatchesRecord([]);
            apiRequest("get", `${env.MYMATCHES}/${id}?skip=${skip}&limit=${limit}`).then((response) => {
                if(response.status === 200){
                    if(response?.data?.data?.[0].profiles && response?.data?.data?.[0].profiles?.length){                        
                        setMatchesRecord(response?.data?.data?.[0].profiles);
                        setMatchesCount(response?.data?.data?.[0].totalCount?.[0]?.totalCount);
                        setFilter(response?.data?.filter);
                        setIsLoading(false);
                        setCurrentPageNumber(currentPage);
                    }else{
                        setFilter(response?.data?.filter);
                        setMatchesRecord([]);
                        setIsLoading(false);
                        setMatchesCount(0);
                        setCurrentPageNumber(1);
                    }
                }else{
                    setMatchesRecord([]);
                    setIsLoading(false);
                    setMatchesCount(0);
                    setCurrentPageNumber(1);
                }
            }).catch((error) => {
                console.error('Error in getting the my matches',error);   
                setIsLoading(false);
                setMatchesCount(0);
                setCurrentPageNumber(1);
            })
        } catch(error){
            console.error('error in getting the my matches',error);
            setIsLoading(false);
            setMatchesCount(0);
        }
    };
    const [activeClass,setActiveClass] = useState(queryParams?.get('tab') ? Number(queryParams.get('tab')) : 1);
    const handleClick = (value) => {
        setPageNumber(1);
        setCurrentPageNumber(1);
        setActiveClass(value);
        setMatchesCount(0);
        setMatchesRecord([]);
        setMatchesRecordTwoWays([]);
        setRecentlyViewed([]);
        setMutualMatches([]);
        setIsMyMatchesFilter(false);
        setSearchData({});
    };
    const handleReset = (obj) => {
        setIsMyMatchesFilter(false);
        setFilterObj({});
        setSearchData({});
        navigate(`/matches?pageNumber=${1}&tab=${1}`);
        getMyMatchesData(0,env.LIMIT,1,userDetails?.userId);
    };
    const handleApply = (obj) => {
        let object = {
            "recentlyJoined": obj?.recently?.value ? obj?.recently?.value : '',
            "basicageMin": obj?.age?.value && obj?.age?.value?.length ? obj?.age?.value[0] : '',
            "basicageMax": obj?.age?.value && obj?.age?.value?.length ? obj?.age?.value[1] : '',
            "basicHeightMin": obj?.height?.minValue || '',
            "basicHeightMax": obj?.height?.maxValue || '',
            "educationAnnualIncome": obj?.annualIncome?.value && obj?.annualIncome?.value?.length ? obj?.annualIncome.value : '',
            "basicMaritalStatus": obj?.maritalStatus?.value && obj?.maritalStatus?.value?.length ? obj?.maritalStatus.value : '',
            "basicReligion": obj?.religion?.value && obj?.religion?.value?.length ? obj?.religion.value : '',
            "basicMotherTongue": obj?.motherTongue?.value && obj?.motherTongue?.value?.length ? obj?.motherTongue.value : '',
            "basicCaste": obj?.caste?.value && obj?.caste?.value?.length ? obj?.caste.value : '',
            "horoscopeManglik": obj?.manglik?.value && obj?.manglik?.value?.length ? obj?.manglik.value : '',
            "locationCountry": obj?.locationCountry?.value ? typeof obj?.locationCountry?.value === 'string' ? [obj?.locationCountry?.value] : obj?.locationCountry?.value : '',
            "locationState": obj?.locationState?.value ? typeof obj?.locationState?.value === 'string' ? [obj?.locationState?.value] : obj?.locationState?.value : '',
            "locationCity": obj?.locationCity?.value ? typeof obj?.locationCity?.value === 'string' ? [obj?.locationCity?.value] : obj?.locationCity?.value : '',
            "educationHighestQualification": obj?.education?.value && obj?.education?.value?.length ? obj?.education.value : '',
            "educationEmployeedIn": obj?.employedIn?.value && obj?.employedIn?.value?.length ? obj?.employedIn.value : '',
            "educationOccupation": obj?.occupation?.value && obj?.occupation?.value?.length ? obj?.occupation.value : '',
            "lifeStyleEatingHabits": obj?.eatingHabit?.value && obj?.eatingHabit?.value?.length ? obj?.eatingHabit.value : '',
            "horoscopeStar": obj?.horoscope?.value && obj?.horoscope?.value?.length ? obj?.horoscope.value : '',
            "basicDisability": obj?.basicDisability?.value && obj?.basicDisability?.value?.length ? obj?.basicDisability.value : '',
            "lifeStyleDrinkingHabits": obj?.lifeStyleDrinkingHabits?.value && obj?.lifeStyleDrinkingHabits?.value?.length ? obj?.lifeStyleDrinkingHabits.value : '',
            "lifeStyleSmokingHabits": obj?.lifeStyleSmokingHabits?.value && obj?.lifeStyleSmokingHabits?.value?.length ? obj?.lifeStyleSmokingHabits.value : '',
        };
        // Filter object to remove empty values
        object = Object.entries(object).reduce((acc, [key, value]) => {
            if (value !== '') {
                acc[key] = value;
            }
            return acc;
        }, {});     
        let page = pageNumber ? Number(pageNumber) : 1
        object.userId = userDetails?.userId;
        object.limit = env.LIMIT;
        object.skip = (page * env.LIMIT) - env.LIMIT;
        object.searchType = 'apply';        
        setIsMyMatchesFilter(true);
        setSearchData(object);
        getMyMatchesFilterData(object, page);
    };    
    const getMyMatchesFilterData = (object,currentPage) => {
        try {
            setIsLoading(true);
            setMatchesRecord([]);
            apiRequest("post", env.GETMYMATCHESFILTER,object).then((response) => {
                if(response.status === 200){
                    if(response?.data?.data?.[0].profiles && response?.data?.data?.[0].profiles?.length){
                        setMatchesRecord(response?.data?.data?.[0].profiles);
                        setMatchesCount(response?.data?.data?.[0].totalCount?.[0]?.totalCount)
                        setFilter(response?.data?.filter);
                        setIsLoading(false);
                        setCurrentPageNumber(currentPage);                        
                    }else{
                        if(response?.data?.filter){
                            setFilter(response?.data?.filter);
                        }
                        setMatchesRecord([]);
                        setIsLoading(false);
                        setMatchesCount(0);
                        setCurrentPageNumber(1);
                    }
                }
            }).catch((error) => {
                console.error('Error in getting the my matches',error);   
                setIsLoading(false);
                setMatchesCount(0);
                setCurrentPageNumber(1);
            })
        } catch(error){
            console.error('error in getting the my matches',error);
            setIsLoading(false);
            setMatchesCount(0);
        }
    };
    const serachBasicFilter = (object,currentPage) => {
        try {
            setIsLoading(true);
            setMatchesRecord([]);
            apiRequest(searchData?.filterId ? "get" : "post", searchData?.filterId ? `${env.GETSEARCHFROMSAVEDSEARCH}/${searchData?.filterId}?skip=${object?.skip || 0}&limit=${env.LIMIT}` : env.BASICSEARCH2,searchData?.filterId ? '' : object).then((response) => {
                if(response.status === 200){
                    if(response?.data?.data?.[0].profiles && response?.data?.data?.[0].profiles?.length){
                        setMatchesRecord(response?.data?.data?.[0].profiles);
                        setMatchesCount(response?.data?.data?.[0].totalCount?.[0]?.totalCount)
                        setFilter(response?.data?.filter);
                        
                        setIsLoading(false);
                        setCurrentPageNumber(currentPage);
                    }else{
                        setMatchesRecord([]);
                        setMatchesCount(0);
                        setIsLoading(false);
                        if(response?.data?.filter){
                            setFilter(response?.data?.filter);
                        }
                        setCurrentPageNumber(1);
                    }
                }else{
                    setMatchesRecord([]);
                    setMatchesCount(0);
                    setIsLoading(false);
                    setCurrentPageNumber(1);
                }
            }).catch((error) => {
                console.error('Error in getting the my matches',error);
                setIsLoading(false);
                setMatchesCount(0);
                setCurrentPageNumber(1);
            })
        } catch (error) {
            console.error('Error in getting the basic search');
            setIsLoading(false);
            setMatchesCount(0);
        }
    };
    const handleSearchPaginationTwoWays = (skip,limit,currentPage,id) => {
        try {
            setIsLoading(true);
            setMatchesRecordTwoWays([]);
            setMatchesCount(0);
            apiRequest("get", `${env.GETTWOWAYMATCH}/${id}?skip=${skip}&limit=${limit}`).then((response) => {
                if(response.status === 200){
                    if(response?.data?.data?.[0]?.profiles && response?.data?.data?.[0]?.profiles?.length){
                        setMatchesRecordTwoWays(response?.data?.data?.[0].profiles);
                        setMatchesCount(response?.data?.data?.[0].totalCount?.[0]?.totalCount);
                        setFilter(response?.data?.filter);
                        setIsLoading(false);
                        setCurrentPageNumber(currentPage);
                    }else{
                        setMatchesRecordTwoWays([]);
                        setIsLoading(false);
                        setCurrentPageNumber(1);
                        setMatchesCount(0);
                    }
                }else{
                    setMatchesRecordTwoWays([]);
                    setIsLoading(false);
                    setMatchesCount(0);
                    setCurrentPageNumber(1);
                }
            }).catch((error) => {
                console.error('Error in getting the my matches',error);   
                setIsLoading(false);
                setMatchesCount(0);
                setCurrentPageNumber(1);
            })
        } catch(error){
            console.error('error in getting the my matches',error);
            setIsLoading(false);
            setMatchesCount(0);
        }
    };
    const handleSearchPagination = (batch,limit,currentPage) => {
        navigate(`/matches?pageNumber=${currentPage}&tab=${activeClass}`);        
        if(searchData && Object.keys(searchData).length){
            let searchDataObj = {...searchData}
            if(searchDataObj?.saveSearchAs){
                delete searchDataObj?.saveSearchAs;
            }
            if(searchDataObj?.searchType === 'Basic'){
                const object = {...searchDataObj,userId:userDetails?.userId,skip:batch,limit:limit};
                serachBasicFilter(object,currentPage);
            }else if(searchDataObj?.searchType === 'Advance'){
                const object = {...searchDataObj,userId:userDetails?.userId,skip:batch,limit:limit};
                serachBasicFilter(object,currentPage);
            }else if(searchDataObj?.searchType === 'Deep'){
                const object = {...searchDataObj,userId:userDetails?.userId,skip:batch,limit:limit};
                serachBasicFilter(object,currentPage);
            }else if(searchDataObj?.searchType === 'apply'){                
                const object = {...searchDataObj,userId:userDetails?.userId,skip:batch,limit:limit}
                setFilterObj(object);
                getMyMatchesFilterData(object, currentPage);
            }
        }else {
            getMyMatchesData(batch,limit,currentPage,userDetails.userId);
        }
    };

    //like-dislike
    const handleLikeDisLike = (action,index,id,value) => {
        try {
            let likeDisLike = {
                userId:userDetails?.userId,
                likeProfileId:id,
                action:action
            }
            setIsLoading(true);
            apiRequest("post", `${env.MYLIKEPROFILE}`,likeDisLike).then((response) => {
                if(response.status === 200){
                    let matchesRecordUpadte = activeClass === 4 ? [...matchesRecordTwoWays] : activeClass === 3 ? [...recentlyViewed] : activeClass === 2 ? value === "manager" ? [...recommendationByManager] : [...mutualMatches] : activeClass === 1 ? [...matchesRecord] : [] 
                    if(matchesRecordUpadte && matchesRecordUpadte.length){
                        matchesRecordUpadte[index].isProfileLike =  action === 'like' ? true : false;
                    }
                    if(activeClass === 4){
                        setMatchesRecordTwoWays(matchesRecordUpadte);
                    }else if(activeClass === 3){
                        setRecentlyViewed(matchesRecordUpadte)
                    }else if(activeClass === 2){
                        if(value === "manager"){
                            setRecommendationByManager(matchesRecordUpadte);
                        }else {
                            setMutualMatches(matchesRecordUpadte)
                        }
                    }else if(activeClass === 1){
                        setMatchesRecord(matchesRecordUpadte)
                    }
                    setIsLoading(false);
                }else{
                    setIsLoading(false);
                }
            }).catch((error) => {
                console.error('Error in like or dislike',error);   
                setIsLoading(false);
            });
        } catch (error) {
            console.error('Error in like or dislike');
            setIsLoading(false);
        }
    }

    //useEffect
    useEffect(() => {
        if(!userDetails?.profileActiveDate || (userDetails?.profileActiveDate < new Date().getTime())){
            setMatchesRecord([]);
            setMatchesCount(0);
            setMatchesRecordTwoWays([]);
            if(userDetails?.userId && Number(pageNumber)){
                let page = Number(pageNumber);
                navigate(`/matches?pageNumber=${pageNumber}&tab=${activeClass}`);
                if(activeClass === 1){
                    if(searchData && Object.keys(searchData).length){
                        if(searchData?.searchType === 'Basic'){
                            const object = {...searchData,userId:userDetails?.userId,skip:(page * env.LIMIT) - env.LIMIT,limit:env.LIMIT};
                            serachBasicFilter(object,page);
                        }else if(searchData?.searchType === 'Advance'){
                            const object = {...searchData,userId:userDetails?.userId,skip:(page * env.LIMIT) - env.LIMIT,limit:env.LIMIT};
                            serachBasicFilter(object,page);
                        }else if(searchData?.searchType === 'Deep'){
                            const object = {...searchData,userId:userDetails?.userId,skip:(page * env.LIMIT) - env.LIMIT,limit:env.LIMIT};
                            serachBasicFilter(object,page);
                        }else if(searchData?.searchType === 'apply'){
                            const object = {...searchData,userId:userDetails?.userId,skip:(page * env.LIMIT) - env.LIMIT,limit:env.LIMIT}
                            getMyMatchesFilterData(object, page);
                        }
                    }else if(isMyMatchesFilter === false) {
                        getMyMatchesData((page * env.LIMIT) - env.LIMIT,env.LIMIT,page,userDetails?.userId)
                    }
                }else if(activeClass === 4){
                    handleSearchPaginationTwoWays((page * env.LIMIT) - env.LIMIT,env.LIMIT,page,userDetails?.userId)
                }else if(activeClass === 3){
                    handleRecentlyViewed((page * env.LIMIT) - env.LIMIT,env.LIMIT,page,userDetails?.userId)
                }else if(activeClass === 2) {
                    handleMutualMatches((page * env.LIMIT) - env.LIMIT,env.LIMIT,page,userDetails?.userId);
                    handleGetRecommendation((page * env.LIMIT) - env.LIMIT,env.LIMIT,page,userDetails?.userId);
                }
            }else{
                setFilter({});
                setIsLoading(false);
                setMatchesRecord([]);
                setMatchesCount(0);
            }
        }
    },[userDetails,pageNumber,searchData,activeClass]);
    useEffect(() => {
        setMatchesCount(matchesCount);
    },[matchesCount])
    useEffect(() => {
        if(queryParams?.get('tab') && Number(queryParams?.get('tab')) >= 5){
            navigate(`/matches?pageNumber=${1}&tab=${1}`);
        }
    },[queryParams])

    const handleRecentlyViewed = (skip,limit,currentPage,id) => {
        try {
            setIsLoading(true);
            setRecentlyViewed([]);
            apiRequest("get", `${env.GETRECENTLYVIEWEDPROFILE}/${id}?skip=${skip}&limit=${limit}`).then((response) => {
                if(response.status === 200){
                    if(response?.data?.data?.[0]?.profiles && response?.data?.data?.[0]?.profiles?.length){
                        navigate(`/matches?pageNumber=${currentPage}&tab=${activeClass}`);
                        setRecentlyViewed(response?.data?.data?.[0].profiles);
                        setMatchesCount(response?.data?.data?.[0].totalCount?.[0]?.totalCount);
                        setFilter(response?.data?.filter);
                        setIsLoading(false);
                        setCurrentPageNumber(currentPage);
                    }else{
                        setRecentlyViewed([]);
                        setIsLoading(false);
                        setMatchesCount(0);
                        setCurrentPageNumber(1);
                    }
                }else{
                    setRecentlyViewed([]);
                    setIsLoading(false);
                    setMatchesCount(0);
                    setCurrentPageNumber(1);
                }
            }).catch((error) => {
                console.error('Error in getting the my matches',error);   
                setIsLoading(false);
                setMatchesCount(0);
                setCurrentPageNumber(1);
            })
        } catch(error){
            console.error('error in getting the my matches',error);
            setIsLoading(false);
            setMatchesCount(0);
            setCurrentPageNumber(1);
        }
    };
    const fetchPartnerDetail = async () => {
        if (partnerDetail === null && userDetails?.userId) {
            try {
                setIsLoading(true);
                await dispatch(getPartnerAction(userDetails.userId));
                // setIsLoading(false);
            } catch (error) {
                console.error('Error',error);
                // setIsLoading(false);
            }
        }
    };
    const handleMutualMatches = async(skip,limit,currentPage,id) => {
        try {
            setIsLoading(true);
            // setMutualMatches([]);
            if(partnerDetail === null){
                await fetchPartnerDetail();
            }
            await apiRequest("get", `${env.GETMUTUALMATCHES}/${id}?skip=${skip}&limit=${limit}`).then((response) => {
                if(response.status === 200){
                    if(response?.data?.data?.[0]?.profiles && response?.data?.data?.[0]?.profiles?.length){
                        setMutualMatches(response?.data?.data?.[0].profiles);
                        setMatchesCount(response?.data?.data?.[0].totalCount?.[0]?.totalCount);
                        setIsLoading(false);
                        setCurrentPageNumber(currentPage);
                        navigate(`/matches?pageNumber=${currentPage}&tab=${activeClass}`);
                    }else{
                        setMutualMatches([]);
                        setIsLoading(false);
                        setMatchesCount(0);
                        setCurrentPageNumber(1);
                    }
                }else{
                    setMutualMatches([]);
                    setIsLoading(false);
                    setMatchesCount(0);
                    setCurrentPageNumber(1);
                }
            }).catch((error) => {
                if(error.response.data.message === 'Add Partner Prefrence to seee mututal matches'){
                    setMututalMessage('Add Partner Prefrence To See Mututal Matches');
                }
                console.error('Error in getting the my matches',error);   
                setIsLoading(false);
                setMatchesCount(0);
                setCurrentPageNumber(1);
            });
        } catch(error){
            console.error('error in getting the my matches',error);
            setIsLoading(false);
            setMatchesCount(0);
            setCurrentPageNumber(1);
        }
    };
    const handleGetRecommendation = async(skip,limit,currentPage,id) => {
        try {
            setIsLoading(true);
            setRecommendationByManager([]);
            setMatchesCountManager(0);
            await apiRequest("get", `${env.GETRECOMMENDATIONBYRELATIONSHIPMANAGER}/${id}?skip=${skip}&limit=${limit}`).then((response) => {
                if(response.status === 200){
                    if(response?.data?.data?.[0]?.profiles && response?.data?.data?.[0]?.profiles?.length){
                        setRecommendationByManager(response?.data?.data?.[0]?.profiles);
                        setMatchesCountManager(response?.data?.data?.[0].totalCount?.[0]?.totalCount);
                        setCurrentPageNumberManager(currentPage);
                        setIsLoading(false);
                        navigate(`/matches?pageNumber=${currentPage}&tab=${activeClass}`);
                    }else{
                        setIsLoading(false);
                        setMatchesCountManager(0);
                        setRecommendationByManager([]);
                    }
                }else{
                    setIsLoading(false);
                    setMatchesCountManager(0);
                    setRecommendationByManager([]);
                }
            }).catch((error) => {
                console.error('Error in getting relationship manager data',error);   
                setIsLoading(false);
                setMatchesCountManager(0);
                setRecommendationByManager([]);
            });
        } catch(error){
            console.error('error in getting relationship manager data',error);
            setIsLoading(false);
            setMatchesCountManager(0);
            setRecommendationByManager([]);
        }
    };
    return (
        <>
            {
                isLoading ? 
                <>
                    <Spinner /> 
                </>
                : null
            }
            {
                !userDetails?.profileActiveDate || (userDetails?.profileActiveDate < new Date().getTime()) ? 
                    <div className="lg:w-full px-[15px] md:px-0 xl:max-w-[1140px] lg:max-w-[960px] md:max-w-[720px] max-w-full mx-auto xl:mt-[90px] xl:mb-[100px] lg:mt-[60px] lg:mb-[60px] mt-[40px] mb-[40px]">
                        <div className="md:px-[15px] mb-[25px] sm:block flex overflow-auto">
                            <Button
                                class={`${activeClass === 1 ? 'bg-[#ed1c24] text-[#fff] border-[#ed1c24]' : 'text-[#b7892d] border-[#b7892d]'} border px-[15px] sm:min-w-[auto] min-w-[max-content] leading-[normal] py-[6px] mr-[11px] rounded-[50px] text-[#b7892d] text-[16px] font-family-Quicksand-Bold`}
                                buttonName={'My Matches'}
                                onClick={() => handleClick(1)}
                            />
                            <Button
                                class={`${activeClass === 2 ? 'bg-[#ed1c24] text-[#fff] border-[#ed1c24]' : 'text-[#b7892d] border-[#b7892d]'} border px-[15px] sm:min-w-[auto] min-w-[max-content] leading-[normal] py-[6px] mr-[11px] rounded-[50px] text-[#b7892d] text-[16px] font-family-Quicksand-Bold`}
                                buttonName={'More Matches'}
                                onClick={() => handleClick(2)}
                            />
                            <Button
                                class={`${activeClass === 3 ? 'bg-[#ed1c24] text-[#fff] border-[#ed1c24]' : 'text-[#b7892d] border-[#b7892d]'} border px-[15px] sm:min-w-[auto] min-w-[max-content] leading-[normal] py-[6px] mr-[11px] rounded-[50px] text-[#b7892d] text-[16px] font-family-Quicksand-Bold`}
                                buttonName={'Recently Viewed'}
                                onClick={() => handleClick(3)}
                            />
                            <Button
                                class={`${activeClass === 4 ? 'bg-[#ed1c24] text-[#fff] border-[#ed1c24]' : 'text-[#b7892d] border-[#b7892d]'} border px-[15px] sm:min-w-[auto] min-w-[max-content] leading-[normal] py-[6px] mr-[11px] rounded-[50px] text-[#b7892d] text-[16px] font-family-Quicksand-Bold`}
                                buttonName={'Two Ways Matches'}
                                onClick={() => handleClick(4)}
                            />
                        </div>
                        <div className={`grid md:grid-cols-1 lg:grid-cols-4`}>
                            <div className={`md:px-[15px]`}>
                                {
                                    activeClass === 2 || activeClass === 3 ? 
                                        <ProfilePicture
                                            imageWrapper={'w-[160px] h-[160px] mx-auto relative mx-auto profile__wrapper after:top-[-20px] after:left-[-21px] after:bg-no-repeat after:bg-center after:absolute after:bg-[url("../../assets/images/png/frame.png")]'}
                                            bgWrapper={'px-[20px] pt-[40px] pb-[15px] mb-[20px]'}
                                        />
                                    : activeClass === 1 ?
                                        <MyMatchesFilter 
                                            handleReset={(obj) => handleReset(obj)}
                                            handleApply={(obj) => handleApply(obj)}
                                            filterObj={filter}
                                        />
                                    : null
                                }
                            </div>
                            <div className={`${activeClass === 4 ? 'md:col-span-4' : 'md:col-span-3'} ${activeClass === 4 && !isLoading && !(matchesRecordTwoWays && matchesRecordTwoWays.length) ? 'min-h-[300px]' : ''} md:px-[15px]`}>
                            {
                                activeClass === 1 ? 
                                <>
                                    <div className="xms:flex xms:justify-between mb-[25px] xms:items-center">
                                        <h2 className="text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular text-[30px] md:text-[34px] lg:text-[44px] lg:leading-[52.8px] md:leading-[30px] leading-[normal]">My Matches</h2>
                                        <h3 className="text-[22px] xms:mt-0 mt-[15px] leading-[normal] text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular xms:text-[30px] xms:leading-[45px]">{matchesCount} Matches</h3>
                                    </div>
                                    {matchesRecord && matchesRecord.length ?
                                        <MyMatchesUserDetail
                                            userDetails={matchesRecord}
                                            matchesCount={matchesCount}
                                            getMyMatchesData={(batch,limit,currentPage) => handleSearchPagination(batch,limit,currentPage)}
                                            page={currentPageNumber}
                                            handleLikeDisLike={(action,index,id) => handleLikeDisLike(action,index,id)}
                                            imageClass={'lg:w-[260px] w-[260px]'}
                                        />
                                    : (!isLoading && !(matchesRecord && matchesRecord.length) ? <span className="block font-family-Quicksand-SemiBold text-[18px] leading-[normal] my-[15px]">No Match Found!</span> : null)}
                                </>
                                :
                                activeClass === 2 ? 
                                <>
                                    {
                                        !isLoading ? 
                                        <>
                                            <h2 className="text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular text-[30px] md:text-[34px] lg:text-[44px] mb-[25px] lg:leading-[52.8px] md:leading-[30px] leading-[normal]">More Matches</h2>
                                            <h4 className="text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular md:text-[30px] leading-[1.2] mb-[12px] text-[24px] text-stroke">Daily Recommendation by Resource Manager</h4>
                                            {recommendationByManager && recommendationByManager.length ?  
                                                <UserDetail
                                                    userDetails={recommendationByManager}
                                                    classWrapper={'mx-[-15px] grid grid-cols-1 ms:grid-cols-2 lg:grid-cols-3'}
                                                    matchesCount={matchesCountManager}
                                                    getMyLikeOrLikerData={(batch, limit, currentPage) => handleGetRecommendation(batch, limit, currentPage, userDetails?.userId)}
                                                    userId={userDetails?.userId}
                                                    handleLikeDisLike={(action, id, batch, limit, currentPage, index) => handleLikeDisLike(action, index,id,'manager',batch, limit, currentPage)}
                                                    page={currentPageNumberManager}
                                                />
                                                : (!isLoading && !(recommendationByManager && recommendationByManager.length) ? <span className="block font-family-Quicksand-SemiBold text-[18px] leading-[normal] my-[15px]">No Recommendation Found!</span> : null)
                                            }
                                            <h4 className="text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular md:text-[30px] leading-[1.2] mb-[12px] text-[24px] text-stroke mt-[5px]" id="mutualMatches">Mutual Matches</h4>
                                            {
                                                partnerDetail ? (
                                                    mutualMatches && mutualMatches.length ? (
                                                        <UserDetail
                                                            userDetails={mutualMatches}
                                                            classWrapper="mx-[-15px] grid grid-cols-1 ms:grid-cols-2 lg:grid-cols-3"
                                                            matchesCount={matchesCount}
                                                            getMyLikeOrLikerData={(batch, limit, currentPage) => handleMutualMatches(batch, limit, currentPage, userDetails?.userId)}
                                                            userId={userDetails?.userId}
                                                            handleLikeDisLike={(action, id, batch, limit, currentPage, index) => handleLikeDisLike(action, index,id,batch, limit, currentPage)}
                                                            page={currentPageNumber}
                                                            name={"mutualMatches"}
                                                        />
                                                    ) : (!isLoading && !(recentlyViewed && recentlyViewed.length) ? <span className="block font-family-Quicksand-SemiBold text-[18px] leading-[normal] my-[15px]">{mututalMessage}</span> : null)
                                                ) : (
                                                    <span className="block font-family-Quicksand-SemiBold text-[18px] leading-[normal] my-[15px]">you haven't added partner preference</span>
                                                )
                                            }
                                        </> : null
                                    }
                                </>
                                : activeClass === 3 ?
                                <>
                                    <h2 className="text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular text-[30px] md:text-[34px] lg:text-[44px] mb-[25px] lg:leading-[52.8px] md:leading-[30px] leading-[normal]">Recently Viewed</h2>
                                    {recentlyViewed && recentlyViewed.length ?  
                                        <UserDetail
                                            userDetails={recentlyViewed}
                                            classWrapper={'mx-[-15px] grid grid-cols-1 ms:grid-cols-2 lg:grid-cols-3'}
                                            matchesCount={matchesCount}
                                            getMyLikeOrLikerData={(batch, limit, currentPage) => handleRecentlyViewed(batch, limit, currentPage, userDetails?.userId)}
                                            userId={userDetails?.userId}
                                            handleLikeDisLike={(action, id, batch, limit, currentPage, index) => handleLikeDisLike(action, index,id,batch, limit, currentPage)}
                                            page={currentPageNumber}
                                            isRecentlyViewed={true}
                                        />
                                        : (!isLoading && !(recentlyViewed && recentlyViewed.length) ? <span className="block font-family-Quicksand-SemiBold text-[18px] leading-[normal] my-[15px]">No Recently Viewed Found!</span> : null)
                                    }
                                </>
                                : activeClass === 4 ? 
                                <>
                                    <div className="xms:flex xms:justify-between mb-[25px] xms:items-center">
                                        <h2 className="text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular text-[30px] md:text-[34px] lg:text-[44px] lg:leading-[52.8px] md:leading-[30px] leading-[normal]">My Matches</h2>
                                        <h3 className="text-[22px] xms:mt-0 mt-[15px] leading-[normal] text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular xms:text-[30px] xms:leading-[45px]">{matchesCount} Matches</h3>
                                    </div>
                                    <div className={`${isLoading && !(matchesRecordTwoWays && matchesRecordTwoWays.length) ? 'min-h-[600px]' : ''} `}>
                                        {matchesRecordTwoWays && matchesRecordTwoWays.length ?
                                            <MyMatchesUserDetail
                                                userDetails={matchesRecordTwoWays}
                                                matchesCount={matchesCount}
                                                getMyMatchesData={(batch,limit,currentPage) => handleSearchPaginationTwoWays(batch,limit,currentPage,userDetails?.userId)}
                                                page={currentPageNumber}
                                                imageClass={'lg:w-[247px] w-[247px] sm:ml-0 sm:mr-0'}
                                                imageClassWrapper={'lg:col-span-3 sm:col-span-4'}
                                                contentClassWrapper={'lg:col-span-9 sm:col-span-8 xl:pl-0 pl-[22px]'}
                                                handleLikeDisLike={(action,index,id) => handleLikeDisLike(action,index,id)}
                                            />
                                        : (!isLoading && !(matchesRecordTwoWays && matchesRecordTwoWays.length) ? <span className="block font-family-Quicksand-SemiBold text-[18px] leading-[normal] my-[15px]">No Match Found!</span> : null)}
                                    </div>
                                </>
                                : null 
                            }
                            </div>
                        </div>
                    </div>
                :
                <div className="flex lg:w-full xl:max-w-[1140px] lg:max-w-[960px] md:max-w-[720px] max-w-full mx-auto lg:min-h-[500px] min-h-[400px] justify-center items-center">
                    <h2 className={'pb-[23px] relative text-[#ed1c24] text-center leading-[1.2] font-family-ZCOOLXiaoWei-Regular xl:text-[48px] lg:text-[40px] md:text-[34px] ms:text-[30px] xms:text-[26px] xxms:text-[22px] after:bg-no-repeat after:w-[100%] after:h-[100%] after:left-0 after:bg-bottom after:absolute after:bg-[url("../../assets/images/png/title_shape.png")]'}>Active Your Profile</h2>
                </div>
            }
        </>
    )
}

export default MyMatches;