import { useEffect, useRef, useState } from "react";
import useValidation from "../../../utils/validation.js";
import CustomInput from "../../molecules/customInput.jsx";
import allCountries from "../../atom/countryCode/countryCode.jsx";
import RadioButton from "../../molecules/radio/radio.jsx";
import razoryPayImage from "../../../assets/images/svg/rzp_payment_icon.svg"
import Button from "../../atom/button.jsx";
import "./index.css"
import Autocomplete from "react-google-autocomplete";
import * as env from '../../../config/env.js';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import React from 'react';
import logoImage from '../../../assets/images/png/logo.png';
import { apiRequest } from "../../../services/index.js";
import { toast } from "react-toastify";
import Spinner from "../../atom/spinner/spinner.jsx";
import { getDashboardDetail } from "../../../stores/action/geDashboard.js";
// import { loadStripe } from '@stripe/stripe-js';

const CheckOut = () => {
    const userDetails = useSelector(state => state?.user?.user);
    const dispatch = useDispatch();
    const checkOutDetail = useSelector(state => state?.setCheckOutReducers?.checkOut);
    const [isLoading,setIsLoading] = useState(false);
    const  { checkErrors,checkAllFields } = useValidation();
    const navigate = useNavigate();
    const dropdownRef = useRef(null);
    const selectDropDown = useRef(null);
    const [countryArray,setCountryArray] = useState(allCountries);
    const typingTimeoutRef = useRef(null);
    const [typedCharacters, setTypedCharacters] = useState('');
    const [isOpen,setIsOpen] = useState(false);
    const [isInputFocused, setIsInputFocused] = useState(true);
    const [formData,setFormData] = useState({
        firstName: {type: 'text',name: 'first name',value: userDetails?.firstName ? userDetails?.firstName : '',rules: "required|max:50"},
        lastName: {type: 'text',name: 'last name',value: userDetails?.lastName ? userDetails?.lastName : '',rules: "required|max:50"},
        mobile:{value: userDetails?.mobile?.mobileNumber ? userDetails?.mobile?.mobileNumber : '',rules:"required|min:10|max:10|regex:^[0-9]+$",name: "mobile",error: ""},
        pinCode:{value: "",rules:"required",name: "zip code",error: ""},
        email: {value: userDetails?.email ? userDetails?.email : '',rules:"required | regex: \\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+",name: "email",error: ""},
        locationCountry:{value:userDetails?.locationCountry ? userDetails?.locationCountry : '',rules:"required",name: "country",error: ""},
        locationCity:{value:userDetails?.locationCity ? userDetails?.locationCity : '',rules:"required",name: "city",error: ""},
        locationState:{value:userDetails?.locationState ? userDetails?.locationState: '',rules:"required",name: "state",error: ""}
    });
    const [countryObject,setCountryObject] = useState(userDetails?.mobile?.countryCode ? countryArray.find(e => e.dialCode === userDetails?.mobile?.countryCode) : {
        "ru": "Индия",
        "lt": "Indija",
        "tr": "Hindistan",
        "en": "India",
        "flag": "🇮🇳",
        "code": "IN",
        "dialCode": "+91",
        "mask": "99999 99999",
        "maskWithDialCode": "##### #####",
        "name": "+91",
        "key": 96,
        "dialCodeWithOutPlusSign": "91",
        "countryJoin": "India"
    });
    const handleCountryCode = (ele) => {
        setCountryObject(ele);
        setIsOpen(false);
    };
    const handleInputChange = (e) => {
        setCountryArray(allCountries);
        setCountryArray(prevCountryArray => {
            let country = prevCountryArray.filter(option =>
                option.name.toLowerCase().includes(e.target.value.toLowerCase()) || option.countryJoin.toLowerCase().includes(e.target.value.toLowerCase())
            );
            return country;
        });
    };
    const handleToggleCountryCode = () => {
        setIsOpen(!isOpen);
        setCountryArray(allCountries);
        setIsInputFocused(true);
    };
    useEffect(() => {
        if(!checkOutDetail){
            navigate('/membership');
        }
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    const handleOutsideClick = (event) => {
        if (selectDropDown.current && !selectDropDown.current.contains(event.target) && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    const handleChange = (value) => {
        if(!(value.target.value)){
            checkErrors({
                'field': formData.locationState,
                'name': 'state',
                'validations': formData.locationState.rules,                
                'value': ''
            });
            checkErrors({
                'field': formData.locationCity,
                'name': 'city',
                'validations': formData.locationCity.rules,                
                'value': ''
            });
            checkErrors({
                'field': formData.locationCountry,
                'name': 'country',
                'validations': formData.locationCountry.rules,                
                'value': ''
            });
            setFormData(prevFormData => ({
                ...prevFormData,
                locationState: { ...formData.locationState, value: '' },
                locationCity: { ...formData.locationCity, value: '' },
                locationCountry: { ...formData.locationCountry, value: '' }
            }));
        }
    }
    const handleSelect = (e) => {
        const addressComponents = e?.address_components;
        let state = '';
        let country = '';
        let city = '';

        // Loop through address components and extract the relevant information
        addressComponents?.forEach(component => {
            const types = component.types;
            if (types.includes('administrative_area_level_1')) {
                state = component.long_name; // or component.short_name
            }
            if (types.includes('country')) {
                country = component.long_name; // or component.short_name
            }
            if (types.includes('locality') || types.includes('postal_town')) {
                city = component.long_name; // or component.short_name
            }
        });
        checkErrors({
            'field': formData.locationState,
            'name': 'state',
            'validations': formData.locationState.rules,                
            'value': state
        });
        checkErrors({
            'field': formData.locationCity,
            'name': 'city',
            'validations': formData.locationCity.rules,                
            'value': city
        });
        checkErrors({
            'field': formData.locationCountry,
            'name': 'country',
            'validations': formData.locationCountry.rules,                
            'value': country
        });
        setFormData(prevFormData => ({
            ...prevFormData,
            locationState: { ...formData.locationState, value: state },
            locationCity: { ...formData.locationCity, value: city },
            locationCountry: { ...formData.locationCountry, value: country }
        }));
    };
    const handleSubmit = () => {
        formData.mobile.rules = `required|min:7|max:15|regex:^[0-9]+$`
        checkAllFields(formData).then((valid)=>{
            setFormData({...formData});
            if(valid){
                try {
                    setIsLoading(true);
                    let relationshipManagerId = checkOutDetail?.relationshipManagerId ? checkOutDetail?.relationshipManagerId : '';
                    let featureDisplayId = checkOutDetail?.featureDisplayId ? checkOutDetail?.featureDisplayId : '';
                    let createPaymentOrder = {
                        amount:Number((checkOutDetail?.totalPrice + (checkOutDetail?.totalPrice * 18)/100)?.toFixed(2) * 100),
                        userId:userDetails.userId,
                        planId:[checkOutDetail._id],
                        billingDetails: {
                            firstName: formData.firstName.value,
                            lastName: formData.lastName.value,
                            locationState: formData.locationState.value,
                            locationCountry: formData.locationCountry.value,
                            locationCity:   formData.locationCity.value,
                            pinCode: formData.pinCode.value,
                            mobile: formData.mobile.value,
                            email: formData.email.value
                        }
                    }
                    if (featureDisplayId) {
                        createPaymentOrder.planId.push(featureDisplayId);
                    }
                    if (relationshipManagerId) {
                        createPaymentOrder.planId.push(relationshipManagerId);
                    }
                    apiRequest("post", env.CREATEPAYMENTORDER, createPaymentOrder).then(async(response) => {
                        if(response.status === 200 && response?.data?.data?.order_id){
                            const options = {
                                key: env.PAYMENTID, // Replace with your Razorpay Key ID
                                amount: Number((checkOutDetail?.totalPrice + (checkOutDetail?.totalPrice * 18)/100)?.toFixed(2) * 100), // Amount in paise (e.g., 1000 paise = ₹10)
                                currency: 'INR',
                                name: 'Rishta Matrimony',
                                description: '',
                                order_id:response?.data?.data?.order_id,
                                image: logoImage, // Optional
                                handler: function(res) {
                                    setIsLoading(true);
                                    const data = {
                                        razorpay_payment_id: res.razorpay_payment_id,
                                        razorpay_order_id: res.razorpay_order_id,
                                        razorpay_signature: res.razorpay_signature,
                                        userId:userDetails.userId,
                                        order_id:response?.data?.data?.order_id,
                                        status:'paid'
                                    };
                                    try {
                                        apiRequest("post", env.SUCCESSPAYMENTORDER, data).then(async(resp) => {
                                            await dispatch(getDashboardDetail(userDetails.userId));
                                            navigate('/');
                                            setIsLoading(false);
                                        }).catch((error) => {
                                            setIsLoading(false);
                                            console.error('Error after payment',error);
                                            toast.error('Please try it again', {
                                                position: 'top-right',
                                            });
                                        })
                                    } catch (error) {
                                        setIsLoading(false);
                                        console.error('Error after payment');
                                        toast.error('Please try it again', {
                                            position: 'top-right',
                                        });
                                    }
                                },
                                prefill: {
                                    name: `${formData?.firstName?.value} ${formData?.lastName?.value}`,
                                    email: formData?.email?.value || '',
                                    contact: formData?.mobile?.value || ''
                                },
                                notes: {
                                    address: `${formData?.locationCity?.value} ${formData?.locationState?.value} ${formData?.locationCountry?.value}`
                                },
                                theme: {
                                    color: '#F37254'
                                },
                                modal: {
                                    escape: false, // Prevent closing with escape key
                                    backdropclose: false,
                                    ondismiss: function() {
                                        setIsLoading(true);
                                        const data = {
                                            userId:userDetails.userId,
                                            order_id:response?.data?.data?.order_id,
                                            status:'cancelled'
                                        };                                        
                                        try {
                                            apiRequest("post", env.SUCCESSPAYMENTORDER, data).then(async() => {
                                                setIsLoading(false);
                                            }).catch((error) => {
                                                setIsLoading(false);
                                                console.error('Error after payment',error);
                                                toast.error('Please try it again', {
                                                    position: 'top-right',
                                                });
                                            })
                                        } catch (error) {
                                            setIsLoading(false);
                                            console.error('Error after payment');
                                            toast.error('Please try it again', {
                                                position: 'top-right',
                                            });
                                        }
                                    }
                                }
                            };
                            var rzp1 = new window.Razorpay(options);
                            rzp1.open();
                            setIsLoading(false);
                        }else{
                            toast.error('Please try it again', {
                                position: 'top-right',
                            });
                            setIsLoading(false);
                        }
                    }).catch((error) => {
                        setIsLoading(false);
                        if (error?.response?.data?.message) {
                            toast.error(error.response.data.message, {
                                position: 'top-right',
                            });
                        }
                        console.error('Payment catch error',error);
                    });
                } catch (error) {
                    console.error('Payment error',error);
                    setIsLoading(false);
                }
            }
        });
    };
    const handleKeyDown = (event) => {
        if (!isInputFocused) return;
        // Prevent the default behavior that might cause outer scroll
        event.preventDefault();
    
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }
    
        setTypedCharacters(prev => prev + event.key);
    
        const matchIndex = countryArray.findIndex((ele) => 
            ele.dialCodeWithOutPlusSign.toLowerCase().startsWith((typedCharacters + event.key).toLowerCase()) || ele.name.toLowerCase().startsWith((typedCharacters + event.key).toLowerCase()) || ele.countryJoin.toLowerCase().startsWith((typedCharacters + event.key).toLowerCase())
        );
    
        if (matchIndex !== -1) {
            const matchElement = dropdownRef.current.querySelectorAll('li')[matchIndex];    
            if (matchElement) {
                const dropdown = dropdownRef.current;
                const dropdownRect = dropdown.getBoundingClientRect();
                const matchRect = matchElement.getBoundingClientRect();
    
                // Calculate the new scrollTop to center the matched item
                const offset = matchRect.top - dropdownRect.top - (dropdownRect.height / 2) + (matchRect.height / 2);
                dropdown.scrollTop += offset;
            }
        }
    
        typingTimeoutRef.current = setTimeout(() => {
            setTypedCharacters('');
        }, 1000);
    };
    useEffect(() => {
        if (isOpen) {
            dropdownRef.current.focus();
        }
    }, [isOpen]);
    return (
        <>
            {isLoading && <Spinner />}
            {
                checkOutDetail ? 
                    <div className={'xl:max-w-[1140px] lg:max-w-[100%] xl:mt-[90px] xl:mb-[100px] lg:mt-[60px] lg:mb-[60px] mt-[40px] mb-[40px] w-full px-[15px] mx-auto'}>
                        <div className="mb-[30px]">
                            <h3 className="font-family-Quicksand-Bold text-[26px] text-[#931217] mb-[30px] leading-[normal]">Billing details</h3>
                            <div className="grid md:grid-cols-2 grid-cols-1">
                                <div className="md:pr-[10px] mb-[10px]">
                                    <CustomInput
                                        inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[43px]'}
                                        class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                        label="First Name *"
                                        type="text"
                                        modelValue={formData.firstName.value ? formData.firstName.value?.trim() : ''}
                                        placeHolder="Enter first name"
                                        isDisabled={false}
                                        isReadonly={false}
                                        isDirectFocus={false}
                                        isRequired={false}
                                        isOutline={true}
                                        onChange={(value,event) => {
                                            formData.firstName.value = value ? value?.trim() : '';
                                            checkErrors({
                                                'field': formData.firstName,
                                                'name': 'first name',
                                                'validations': formData.firstName.rules,
                                                'type': formData.firstName.type,
                                                'event': event,
                                                'value':value?.trim()
                                            });
                                            setFormData({...formData});
                                        }}
                                    />
                                    {formData.firstName.error && 
                                        <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{formData.firstName.error}</small>
                                    }
                                </div>
                                <div className="md:pl-[10px] mb-[10px]">
                                    <CustomInput
                                        inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[43px]'}
                                        class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                        label="Last Name *"
                                        type="text"
                                        modelValue={formData.lastName.value ? formData.lastName.value.trim() : ''}
                                        placeHolder="Enter last name"
                                        isDisabled={false}
                                        isReadonly={false}
                                        isDirectFocus={false}
                                        isRequired={false}
                                        isOutline={true}
                                        onChange={(value,event) => {
                                            formData.lastName.value = value ? value.trim() : '';
                                            checkErrors({
                                                'field': formData.lastName,
                                                'name': 'last name',
                                                'validations': formData.lastName.rules,
                                                'type': formData.lastName.type,
                                                'event': event,
                                                'value':value ? value.trim() : ''
                                            });
                                            setFormData({...formData});
                                        }}
                                        onBlur={() => {
                                            const blur = {...formData}
                                            blur.lastName.value = blur?.lastName?.value?.trim();
                                            setFormData(blur);
                                        }}
                                    />
                                    {formData.lastName.error && 
                                        <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{formData.lastName.error}</small>
                                    }
                                </div>
                                <div className="md:pr-[10px] mb-[10px]">
                                    <label className="text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]">City, State, Country *</label>
                                    <Autocomplete
                                        apiKey={env.GOOGLEAPIKEY}
                                        onChange={(e) => handleChange(e)}
                                        onPlaceSelected={(place) => {handleSelect(place,'location')}}
                                        types={['(regions)']}
                                        fields={['address_components']}
                                        className='w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[41px] rounded-[10px]'
                                        placeholder={'Enter city state country'}
                                        defaultValue={formData?.locationCity?.value && formData?.locationState?.value && formData?.locationCountry?.value ? `${formData?.locationCity?.value}, ${formData?.locationState?.value}, ${formData?.locationCountry?.value}` : ''}
                                    />
                                    { formData?.locationCountry?.error && formData?.locationCity?.error && formData?.locationState?.error ?
                                        <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">The city state country field is required</small>
                                        :formData?.locationCountry?.error ? <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">please select the valid address</small> 
                                        :formData?.locationCity?.error ? <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">please select the valid address</small>
                                        :formData?.locationState?.error ? <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">please select the valid address</small>
                                        :null
                                    }
                                </div>
                                <div className="pl-[10px] mb-[10px]">
                                    <CustomInput
                                        inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[43px]'}
                                        class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                        label="ZIP Code *"
                                        type="text"
                                        modelValue={formData?.pinCode?.value}
                                        placeHolder="Enter zip code"
                                        isDisabled={false}
                                        isReadonly={false}
                                        isDirectFocus={false}
                                        isRequired={false}
                                        isOutline={true}
                                        onChange={(value,event) => {
                                            formData.pinCode.value = value ? value.trim() : '';
                                            checkErrors({
                                                'field': formData.pinCode,
                                                'name': 'zip code',
                                                'validations': formData.pinCode.rules,
                                                'type': formData.pinCode.type,
                                                'event': event,
                                                'value':value ? value.trim() : ''
                                            });
                                            setFormData({...formData});
                                        }}
                                    />
                                    {formData.pinCode.error && 
                                        <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{formData.pinCode.error}</small>
                                    }
                                </div>
                                <div className="md:pr-[10px] mb-[10px]">
                                    <label className="text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]">Phone *</label>
                                    <div className="flex relative">
                                        <div ref={selectDropDown} className="cursor-pointer flex items-center rounded-tl-[8px] px-[10px] bg-[#f0eeee] rounded-bl-[8px]" onClick={(event) => {event.preventDefault(); handleToggleCountryCode();}}>
                                            {countryObject?.name} 
                                            <span className="dropDown_arrow"></span>
                                        </div>
                                        {
                                            isOpen ?
                                                <div ref={dropdownRef} onKeyDown={handleKeyDown} className="focus_dropdown bg-white top-[42px] absolute z-10 w-full rounded-[12px] max-h-[240px] overflow-x-auto">
                                                    <input onFocus={() => setIsInputFocused(false)} onBlur={() => setIsInputFocused(true)} type='text' className='w-[-webkit-fill-available] mt-[20px] border-[1px] mx-[20px] mb-[10px] rounded-[20px] px-[10px]' onChange={handleInputChange} placeholder="Search..." />
                                                    <ul>
                                                        {countryArray.length ? countryArray.map((ele,index) => 
                                                            <li className={`${countryObject?.en === ele?.en ? 'bg-[#f6ecc8]' : ''} cursor-pointer hover:bg-[#f6ecc8] px-[20px] py-[5px] text-[18px] font-family-Quicksand-Regular color-[262626]`} key={index} onClick={(event) => {event.preventDefault(); handleCountryCode(ele);}}>
                                                                {ele.name} {ele?.countryJoin}
                                                            </li>
                                                        ) : <li className='px-[20px] py-[5px]'>No data found</li>}
                                                    </ul>
                                                </div>
                                            : null
                                        }
                                        <CustomInput
                                            inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular border-radius px-[16px] py-[5px] h-[42px]'}
                                            class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                            type="number"
                                            min="1"
                                            modelValue={formData.mobile.value ? formData.mobile.value.trim() : ''}
                                            placeHolder="Enter mobile no"
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            onChange={(value,event) => {
                                                formData.mobile.value = value;
                                                formData.mobile.rules = `required|min:7|max:15|regex:^[0-9]+$`
                                                checkErrors({
                                                    'field': formData.mobile,
                                                    'name': 'mobile',
                                                    'validations': formData.mobile.rules,
                                                    'type': formData.mobile.type,
                                                    'event': event,
                                                    'value':value
                                                });
                                                setFormData({...formData});
                                            }}
                                            onKeyPress={(event)=>{
                                                if(event.event.charCode === 45 || event.event.charCode === 43 || event.event.charCode === 46 || event.event.key === 'e' || event.event.key === 'E'){
                                                    event.event.preventDefault();
                                                }
                                            }}
                                            onKeyDown={(event)=>{
                                                if(event.event.key === 'ArrowUp' || event.event.key === 'ArrowDown'){
                                                    event.event.preventDefault();
                                                }
                                            }}
                                        />
                                    </div>
                                    {formData.mobile.error && 
                                        <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{formData.mobile.error}</small>
                                    }
                                </div>
                                <div className="md:pl-[10px]">
                                    <CustomInput
                                        inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[42px]'}
                                        class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                        label="Email address *"
                                        type="text"
                                        modelValue={formData.email.value ? formData.email.value.trim() : ''}
                                        placeHolder="Enter email address"
                                        isDisabled={false}
                                        isReadonly={false}
                                        isDirectFocus={false}
                                        isRequired={false}
                                        isOutline={true}
                                        onChange={(value,event) => {
                                            formData.email.value = value ? value.trim() : '';
                                            checkErrors({
                                                'field': formData.email,
                                                'name': 'email',
                                                'validations': formData.email.rules,
                                                'type': formData.email.type,
                                                'event': event,
                                                'value':value ? value.trim() : ''
                                            });
                                            setFormData({...formData});
                                        }}
                                        onBlur={() => {
                                            const blur = {...formData}
                                            blur.email.value = blur?.email?.value?.trim();
                                            setFormData(blur);
                                        }}
                                    />
                                    {formData.email.error && 
                                        <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{formData.email.error}</small>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="mb-[30px]">
                            <h3 className="font-family-Quicksand-Bold text-[26px] text-[#931217] mb-[30px] leading-[normal]">Your order</h3>
                            <div className="overflow-x-auto border border-[#b7892d] rounded-[6px]">
                                <table className="min-w-full">
                                    <thead>
                                        <tr className="bg-[#ead7ab]">
                                            <th className="rounded-bl-[6px] rounded-tl-[6px] md:px-[25px] md:py-[15px] px-[15px] py-[15px] md:text-[24px] text-[20px] font-family-Quicksand-Bold text-[#931217] text-left">Product</th>
                                            <th className="rounded-br-[6px] rounded-tr-[6px] md:px-[25px] md:py-[15px] px-[15px] py-[15px] md:text-[24px] text-[20px] font-family-Quicksand-Bold text-[#931217] text-left">Subtotal</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="md:py-[9px] md:px-[25px] py-[9px] px-[15px]">
                                                <div className="text-[18px] font-family-Quicksand-Bold text-stroke text-[#931217] leading-[normal]">{checkOutDetail?.planName} × 1</div>
                                                <div className="text-[18px] font-family-Quicksand-Bold mt-[10px] text-[#262626] mr-[10px] leading-[normal]">Start Date: <strong className="text-stroke">{checkOutDetail?.planTypeStartDate ? moment(checkOutDetail?.planTypeStartDate).format('DD-MM-YYYY') : '-'}</strong></div>
                                                <div className="text-[18px] font-family-Quicksand-Bold mt-[10px] text-[#262626] mr-[10px] leading-[normal]">End Date: <strong className="text-stroke">{checkOutDetail?.planTypeExpiryDate ? moment(checkOutDetail?.planTypeExpiryDate).format('DD-MM-YYYY') : ''}</strong></div>
                                                <div className="text-[18px] font-family-Quicksand-Bold mt-[10px] text-[#262626] mr-[10px] leading-[normal]">Membership Credits: <strong className="text-stroke">{checkOutDetail?.unlockProfiles}</strong></div>
                                            </td>
                                            <td className="text-[18px] mt-[15px] text-[#262626] font-family-Quicksand-Medium text-stroke text-left md:py-[9px] md:px-[25px] py-[9px] px-[15px] align-baseline">₹ {checkOutDetail?.price}.00</td>
                                        </tr>
                                        {
                                            checkOutDetail.relationshipManagerDuration &&
                                            <tr>
                                                <td className="md:py-[9px] md:px-[25px] py-[9px] px-[15px]">
                                                    <div className="text-[18px] font-family-Quicksand-Bold text-stroke text-[#931217] leading-[normal]">Relationship Manager - {checkOutDetail.relationshipManagerDuration} × 1</div>
                                                    <div className="text-[18px] font-family-Quicksand-Bold mt-[10px] text-[#262626] mr-[10px] leading-[normal]">Start Date: <strong className="text-stroke">{checkOutDetail?.relationshipManagerStartDate ? moment(checkOutDetail?.relationshipManagerStartDate).format('DD-MM-YYYY') : ''}</strong></div>
                                                    <div className="text-[18px] font-family-Quicksand-Bold mt-[10px] text-[#262626] mr-[10px] leading-[normal]">End Date: <strong className="text-stroke">{checkOutDetail?.relationshipManagerExpiryDate ? moment(checkOutDetail?.relationshipManagerExpiryDate).format('DD-MM-YYYY') : ''}</strong></div>
                                                </td>
                                                <td className="text-[18px] mt-[15px] text-[#262626] font-family-Quicksand-Medium text-stroke text-left md:py-[9px] md:px-[25px] py-[9px] px-[15px] align-baseline">₹ {checkOutDetail.relationshipManagerPrice}.00</td>
                                            </tr>
                                        }
                                        {
                                            checkOutDetail?.featureDisplayDuration &&
                                            <tr>
                                                <td className="md:py-[9px] md:px-[25px] py-[9px] px-[15px]">
                                                    <div className="text-[18px] font-family-Quicksand-Bold text-stroke text-[#931217] leading-[normal]">Feature Display - {checkOutDetail?.featureDisplayDuration} × 1</div>
                                                    <div className="text-[18px] font-family-Quicksand-Bold mt-[10px] text-[#262626] mr-[10px] leading-[normal]">Start Date: <strong className="text-stroke">{checkOutDetail?.featureDisplayStartDate ? moment(checkOutDetail?.featureDisplayStartDate).format('DD-MM-YYYY') : ''}</strong></div>
                                                    <div className="text-[18px] font-family-Quicksand-Bold mt-[10px] text-[#262626] mr-[10px] leading-[normal]">End Date: <strong className="text-stroke">{checkOutDetail?.featureDisplayExpiryDate ? moment(checkOutDetail?.featureDisplayExpiryDate).format('DD-MM-YYYY') : ''}</strong></div>
                                                </td>
                                                <td className="text-[18px] mt-[15px] text-[#262626] font-family-Quicksand-Medium text-stroke text-left md:py-[9px] md:px-[25px] py-[9px] px-[15px] align-baseline">₹ {checkOutDetail?.featureDisplayPrice}.00</td>
                                            </tr>
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr className="border-y border-y-[#B7892D]">
                                            <td className="md:py-[9px] md:px-[25px] py-[9px] px-[15px] leading-[1.5em] font-family-Quicksand-Regular text-[20px] text-[#262626] text-left font-bold ">Subtotal</td>
                                            <td className="text-[18px] mt-[15px] text-[#262626] font-family-Quicksand-Medium text-stroke text-left md:py-[9px] md:px-[25px] py-[9px] px-[15px] align-baseline">₹ {checkOutDetail?.totalPrice}.00</td>
                                        </tr>
                                        <tr className="">
                                            <td className="md:py-[9px] md:px-[25px] py-[9px] px-[15px] leading-[1.5em] font-family-Quicksand-Regular text-[20px] text-[#262626] text-left font-bold ">GST</td>
                                            <td className="text-[18px] mt-[15px] text-[#262626] font-family-Quicksand-Medium text-stroke text-left md:py-[9px] md:px-[25px] py-[9px] px-[15px] align-baseline">₹ {((checkOutDetail?.totalPrice * 18)/100).toFixed(2)}</td>
                                        </tr>
                                        <tr className="bg-[#ead7ab]">
                                            <td className="rounded-bl-[6px] rounded-tl-[6px] md:py-[15px] md:px-[25px] px-[15px] py-[15px] md:text-[24px] text-[20px] font-family-Quicksand-Bold text-[#931217] text-left leading-[30px]">Total</td>
                                            <td className="rounded-br-[6px] rounded-tr-[6px] text-[18px] mt-[15px] text-[#262626] font-family-Quicksand-Medium text-stroke text-left md:px-[25px] md:py-[15px] px-[15px] py-[15px]">₹ {(checkOutDetail?.totalPrice + (checkOutDetail?.totalPrice * 18)/100).toFixed(2)}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div className="mt-[70px] bg-[#ead7ab] rounded-[12px] py-[20px] credit_card">
                            <div className="flex px-[25px] flex-wrap">
                                <RadioButton
                                    inputId={`credit_card_debitcard_netbanking`}
                                    isDisabled={false}
                                    isReadonly={false}
                                    isDirectFocus={false}
                                    isRequired={false}
                                    isOutline={true}
                                    name="reasons"
                                    label={'Credit Card/Debit Card/NetBanking'}
                                    class={'font-family-Quicksand-Bold text-[20px] relative cursor-pointer leading-[normal] inline-block text-[#666]'}
                                    checked={true}
                                    inputClass={`setting-styled-radioBtn opacity-0 absolute`}
                                    radioClassWrapper={'mr-[0.5em]'}
                                />
                                <img src={razoryPayImage} alt="razory_pay_image" />
                            </div>
                            <div className="px-[25px]">
                                <p className="relative payment_box w-full p-[1em] my-[1em] text-[0.92em] rounded-[2px] leading-[1.5] bg-[#dcd7e3] text-[#515151] text-stroke font-family-Quicksand-Regular">Pay securely by Credit or Debit card or Internet Banking through Razorpay.</p>
                            </div>
                            <div className="border-t border-t-[#cfc8d8] pt-[15px]">
                                <p className="px-[25px] text-stroke font-family-Quicksand-SemiBold text-[18px] text-[#262626] mb-[20px]">Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our privacy policy</p>
                            </div>
                            <div className="px-[25px]">
                                <Button
                                    buttonName={'Place order'}
                                    class={'text-[#fff] bg-[#ed1c24] rounded-[50px] text-[16px] font-family-Quicksand-SemiBold pt-[9px] pb-[11px] px-[30px] uppercase leading-[normal]'}
                                    onClick={() => handleSubmit()}
                                />
                            </div>
                        </div>
                    </div> 
                : 
                <div className="xl:max-w-[1140px] lg:max-w-[960px] md:max-w-[720px] max-w-full mx-auto lg:pt-[175px] lg:pb-[175px] md:pt-[120px] md:pb-[120px] pt-[140px] pb-[140px] lg:justify-center">
                    <h2 className={'pb-[23px] relative text-[#ed1c24] text-center leading-[1.2] font-family-ZCOOLXiaoWei-Regular xl:text-[48px] lg:text-[40px] md:text-[34px] ms:text-[30px] xms:text-[26px] xxms:text-[22px]'}>Your Cart Is Empty</h2>
                    <Button class="block bg-[#dec31b] text-[#fff] text-[20px] font-family-Quicksand-Medium rounded-[50px] px-[35px] py-[12px] cursor-pointer leading-[normal] mx-auto hover:bg-[#ed1c24]" buttonName={'Continue Shopping'} onClick={() => navigate('/membership')}></Button>
                </div>
            }
        </>
    );
};

export default CheckOut;
